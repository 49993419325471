// views
import { Alert } from 'antd'

// utils
import moment from 'moment'
import _ from 'lodash'
import { formatDate } from '~/utils/app'

export default props => {
  const releaseOn = _.get(props, 'user.release_on')
  const isMuted = releaseOn && moment(releaseOn).isAfter()

  if (isMuted) {
    const message = (
      <div>
        <span>你已被禁言，不能发布或更新内容。将于</span>
        <span className='text-primary px-5'>
          {formatDate(releaseOn)}
        </span>
        <span>自动解封。</span>
      </div>
    )

    return (
      <div className='mt-20'>
        <Alert
          type='warning'
          message={message}
        />
      </div>
    )
  }

  return null
}