import { request, authRequest } from "~/utils/api";

import { openRequest, closeRequest, showAppMessage } from "~/redux/actions/app";

/**
 * Get Search List
 */
export const GET_SEARCH_POST_LIST = "GET_SEARCH_POST_LIST";
export const SET_SEARCH_POST_LIST = "SET_SEARCH_POST_LIST";

export const getSearchPostList = (params) => {
  if (params.sort === "new") {
    params.sort = "-published_at";
  } else if (params.sort === "hot") {
    params.sort = "hot";
  }
  return (dispatch) => {
    dispatch(openRequest(GET_SEARCH_POST_LIST));
    request({
      url: "/posts/search",
      params: params,
    })
      .then((res) => {
        dispatch(closeRequest(GET_SEARCH_POST_LIST));
        const { posts, pager } = res.data;
        dispatch({ type: SET_SEARCH_POST_LIST, list: { posts, pager } });
      })
      .catch((err) => {
        dispatch(closeRequest(GET_SEARCH_POST_LIST));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Get Search Keywords Hot
 */
export const GET_SEARCH_KEYWORDS_LIST = "GET_SEARCH_KEYWORDS_LIST";
export const SET_SEARCH_KEYWORDS_LIST = "SET_SEARCH_KEYWORDS_LIST";

export const getSearchKeywordsList = () => {
  return (dispatch) => {
    dispatch(openRequest(GET_SEARCH_KEYWORDS_LIST));
    request({
      url: "/keywords/hot",
    })
      .then((res) => {
        dispatch(closeRequest(GET_SEARCH_KEYWORDS_LIST));
        const { keywords } = res.data;
        dispatch({ type: SET_SEARCH_KEYWORDS_LIST, list: keywords });
      })
      .catch((err) => {
        dispatch(closeRequest(GET_SEARCH_KEYWORDS_LIST));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};
