import footMaskIcon from "~/public/static/foot-mask.png";
import footVirusIcon from "~/public/static/foot-virus.png";
const SiteFootActive = () => {
  const actives = [
    {
      title: "岂日无衣，与子同袍",
      pic: footMaskIcon.src,
      url: "https://eleduck.com/maskhero",
    },
    {
      title: "远程战疫",
      pic: footVirusIcon.src,
      url: "https://eleduck.com/remote_anti_virus",
    },
  ];

  return (
    <div style={{ marginBottom: "20px" }}>
      {actives.map((active) => (
        <a
          key={Math.random()}
          title={active.title}
          href={active.url}
          target="_blank"
        >
          <img
            src={active.pic}
            alt={active.title}
            style={{
              width: "90px",
              height: "26px",
              margin: "0px 15px 15px 0px",
            }}
          />
        </a>
      ))}
    </div>
  );
};

export default SiteFootActive;
