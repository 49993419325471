import Router from "next/router";
import lscache from "lscache";
import moment from "moment";
import { request, authRequest } from "~/utils/api";
import { setCookie, removeCookie } from "~/utils/cookie";

import { showAppMessage, openRequest, closeRequest, fireEvent } from "./app";
import settings from "../../settings";

export const REFRESH_AUTH_CODE_TEXT = "REFRESH_AUTH_CODE_TEXT";
export const SEND_AUTH_CODE = "SEND_AUTH_CODE";
export const SIGNUP_BY_PHONE = "SIGNUP_BY_PHONE";
export const SIGNIN_BY_PHONE = "SIGNIN_BY_PHONE";
export const RESET_BY_PHONE = "RESET_BY_PHONE";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
export const SET_WECHAT_CODE = "SET_WECHAT_CODE";

const AUTH_CODE_RETRY_KEY = "auth_code_retry_at";

export const sendAuthCode = ({ phone, captcha }) => {
  return (dispatch) => {
    dispatch(openRequest(SEND_AUTH_CODE));
    request({
      url: "/auth/code",
      method: "POST",
      data: { phone, captcha },
    })
      .then(() => {
        dispatch(closeRequest(SEND_AUTH_CODE));
        lscache.set(
          AUTH_CODE_RETRY_KEY,
          moment().add(60, "s").toISOString(),
          2
        );
        dispatch(refreshAuthCodeText());
        dispatch(showAppMessage("验证码已经发送，请注意查收", "success"));
      })
      .catch((err) => {
        dispatch(closeRequest(SEND_AUTH_CODE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const refreshAuthCodeText = () => {
  const codeRetryAt = moment(lscache.get(AUTH_CODE_RETRY_KEY) || undefined);
  const codeRemains = codeRetryAt.diff(moment(), "s");

  return {
    type: REFRESH_AUTH_CODE_TEXT,
    codeRemains,
  };
};

export const signupByPhone = ({ phone, code, password, inviteCode }) => {
  return (dispatch) => {
    dispatch(openRequest(SIGNUP_BY_PHONE));
    request({
      url: "/auth/phone/signup",
      method: "POST",
      data: {
        phone,
        code,
        password,
        invite_code: inviteCode,
      },
    })
      .then((res) => {
        dispatch(closeRequest(SIGNUP_BY_PHONE));
        const { token, user } = res.data;
        setCookie(settings.cookieTokenName, token);
        lscache.set("user", user);
        dispatch(setUserToken(token));
        dispatch(setUserInfo(user));
      })
      .catch((err) => {
        dispatch(closeRequest(SIGNUP_BY_PHONE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const signinByPhone = ({ phone, password }) => {
  return (dispatch) => {
    dispatch(openRequest(SIGNIN_BY_PHONE));
    request({
      url: "/auth/phone/signin",
      method: "POST",
      data: { phone, password },
    })
      .then((res) => {
        dispatch(closeRequest(SIGNIN_BY_PHONE));
        const { token, user } = res.data;
        setCookie(settings.cookieTokenName, token);
        lscache.set("user", user);
        dispatch(setUserToken(token));
        dispatch(setUserInfo(user));
      })
      .catch((err) => {
        dispatch(closeRequest(SIGNIN_BY_PHONE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const resetByPhone = ({ phone, code, password }) => {
  return (dispatch) => {
    dispatch(openRequest(RESET_BY_PHONE));
    request({
      url: "/auth/phone/reset",
      method: "POST",
      data: { phone, code, password },
    })
      .then((res) => {
        dispatch(closeRequest(RESET_BY_PHONE));
        const { token, user } = res.data;
        setCookie(settings.cookieTokenName, token);
        lscache.set("user", user);
        dispatch(setUserToken(token));
        dispatch(setUserInfo(user));
        dispatch(showAppMessage("密码重置成功", "success"));
      })
      .catch((err) => {
        dispatch(closeRequest(RESET_BY_PHONE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const updateUserInfo = (token, userInfo) => {
  return (dispatch) => {
    dispatch(openRequest(UPDATE_USER_INFO));
    authRequest(token, {
      url: "/profile/info",
      method: "POST",
      data: userInfo,
    })
      .then((res) => {
        dispatch(closeRequest(UPDATE_USER_INFO));
        const { user } = res.data;
        dispatch(setUserInfo(user));
        dispatch(showAppMessage("用户资料已更新", "success"));
        dispatch(fireEvent(UPDATE_USER_INFO));
      })
      .catch((err) => {
        dispatch(closeRequest(UPDATE_USER_INFO));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const updateUserAvatar = (token, file) => {
  const formData = new window.FormData();
  formData.append("avatar", file);

  return (dispatch) => {
    dispatch(openRequest(UPDATE_USER_AVATAR));
    authRequest(token, {
      url: "/profile/avatar",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        dispatch(closeRequest(UPDATE_USER_AVATAR));
        const { user } = res.data;
        dispatch(setUserInfo(user));
        dispatch(showAppMessage("头像已更新", "success"));
      })
      .catch((err) => {
        dispatch(closeRequest(UPDATE_USER_AVATAR));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const setUserToken = (token) => {
  return {
    type: SET_USER_TOKEN,
    token,
  };
};

export const getUserInfo = (token) => {
  return (dispatch) => {
    authRequest(token, {
      url: "/profile/info",
    }).then((res) => {
      const { user } = res.data;
      dispatch(setUserInfo(user));
    });
  };
};

export const checkUserInfo = (token, callback) => {
  return (dispatch) => {
    authRequest(token, {
      url: "/profile/info",
    })
      .then((res) => {
        const { user } = res.data;
        if (user.wechat_openid) {
          dispatch(setUserInfo(user));
        } else {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: EXP_WECHAT_TOKEN });
      });
  };
};

export const getUser = (id) => {
  return request({
    url: `/users/${id}`,
  }).then((res) => res.data.user);
};

export const getUserV2 = (token, id) => {
  return authRequest(token, {
    url: `/users/${id}`,
  }).then((res) => res.data.user);
};

export const setUserInfo = (user) => {
  return {
    type: SET_USER_INFO,
    user,
  };
};

export const resetUserInfo = (user) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setUserInfo(user));
      resolve();
    });
  };
};

export const setWechatCode = (code) => {
  return {
    type: SET_WECHAT_CODE,
    code,
  };
};

export const signout = () => {
  return (dispatch) => {
    removeCookie(settings.cookieTokenName);
    dispatch(setUserToken(null));
    dispatch(setUserInfo(null));
    Router.push("/");
  };
};

/* Invite code */
export const SET_INVITE_CODE = "SET_INVITE_CODE";
export const SET_INVITE_VALID = "SET_INVITE_VALID";
export const VERIFY_INVITE = "VERIFY_INVITE";

export const setInviteCode = (inviteCode) => {
  return {
    type: SET_INVITE_CODE,
    inviteCode,
  };
};

export const setInviteValid = (inviteValid) => {
  return {
    type: SET_INVITE_VALID,
    inviteValid,
  };
};

export const verifyInvite = (code) => {
  return (dispatch) => {
    dispatch(openRequest(VERIFY_INVITE));
    request({
      url: "/auth/invite",
      params: { code },
    })
      .then((res) => {
        dispatch(closeRequest(VERIFY_INVITE));
        const { verified } = res.data;

        !verified && dispatch(showAppMessage("无效的邀请码", "error"));
        code && dispatch(setInviteCode(code));
        dispatch(setInviteValid(verified));
      })
      .catch((err) => {
        dispatch(closeRequest(VERIFY_INVITE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Wechat
 */

export const SET_WECHAT_TOKEN = "SET_WECHAT_TOKEN";
export const EXP_WECHAT_TOKEN = "EXP_WECHAT_TOKEN";

export const createWechatToken = () => {
  return (dispatch) => {
    request({
      url: "/auth/wechat/token",
      method: "POST",
    })
      .then((res) => {
        dispatch({ type: SET_WECHAT_TOKEN, token: res.data.wechat_token });
      })
      .catch((err) => {
        dispatch({ type: EXP_WECHAT_TOKEN });
      });
  };
};

export const checkWechatToken = (wechatToken, callback) => {
  return (dispatch) => {
    request({
      url: "/auth/wechat/token",
      method: "GET",
      params: { wechat_token: wechatToken },
    })
      .then((res) => {
        const userToken = res.data.token;
        if (userToken) {
          setCookie(settings.cookieTokenName, userToken);
          dispatch(setUserToken(userToken));
          dispatch(getUserInfo(userToken));
        } else {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: EXP_WECHAT_TOKEN });
      });
  };
};

export const signinByWechat = ({ wechatToken, wechatCode }) => {
  return (dispatch) => {
    request({
      url: "/auth/wechat/signin",
      method: "POST",
      data: {
        wechat_token: wechatToken,
        wechat_code: wechatCode,
      },
    })
      .then((res) => {
        const { token, user } = res.data;
        setCookie(settings.cookieTokenName, token);
        dispatch(setUserToken(token));
        dispatch(setUserInfo(user));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const signupByWechat = ({ wechatToken, wechatCode, inviteCode }) => {
  return (dispatch) => {
    request({
      url: "/auth/wechat/signup",
      method: "POST",
      data: {
        wechat_token: wechatToken,
        wechat_code: wechatCode,
        invite_code: inviteCode,
      },
    })
      .then((res) => {
        const { token, user } = res.data;
        setCookie(settings.cookieTokenName, token);
        dispatch(setUserToken(token));
        dispatch(setUserInfo(user));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const SET_USER_SUGGESTS = "SET_USER_SUGGESTS";
export const getUserSuggests = () => {
  return (dispatch) => {
    request({
      url: "/users/suggest",
      method: "GET",
    }).then((res) => {
      const { fresh_users, active_users } = res.data;
      dispatch({
        type: SET_USER_SUGGESTS,
        freshUsers: fresh_users,
        activeUsers: active_users,
      });
    });
  };
};

export const SET_USER_RANKS = "SET_USER_RANKS";
export const getUserRanks = () => {
  return (dispatch) => {
    request({
      url: "/users/ranks",
      method: "GET",
    }).then((res) => {
      const { users } = res.data;
      dispatch({
        type: SET_USER_RANKS,
        users: users,
      });
    });
  };
};

/**
 * Checkin
 */

export const GET_USER_CHECKIN = "GET_USER_CHECKIN";
export const SET_USER_CHECKIN = "SET_USER_CHECKIN";

export const getUserCheckin = (token) => {
  return (dispatch) => {
    dispatch(openRequest(GET_USER_CHECKIN));
    authRequest(token, {
      url: "/checkin",
      method: "GET",
    })
      .then((res) => {
        dispatch(openRequest(GET_USER_CHECKIN));
        const { checkin, monthly_checkins } = res.data;
        dispatch({
          type: SET_USER_CHECKIN,
          checkin: checkin,
          monthlyCheckins: monthly_checkins,
        });
      })
      .catch((err) => {
        dispatch(openRequest(GET_USER_CHECKIN));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const setUserCheckin = (token) => {
  return (dispatch) => {
    dispatch(openRequest(GET_USER_CHECKIN));
    authRequest(token, {
      url: "/checkin",
      method: "POST",
    })
      .then(() => {
        dispatch(closeRequest(GET_USER_CHECKIN));
        dispatch(getUserCheckin(token));
      })
      .catch((err) => {
        dispatch(closeRequest(GET_USER_CHECKIN));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Exp records
 */
export const SET_EXP_RECORD_LIST = "SET_EXP_RECORD_LIST";
export const getExpRecordList = (token, params) => {
  return (dispatch) => {
    authRequest(token, {
      url: "/exp_records",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_EXP_RECORD_LIST, list: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Ele records
 */
export const SET_ELE_RECORD_LIST = "SET_ELE_RECORD_LIST";
export const getEleRecordList = (token, params) => {
  return (dispatch) => {
    authRequest(token, {
      url: "/ele_records",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_ELE_RECORD_LIST, list: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * View user
 */
export const CREATE_USER_VIEW = "CREATE_USER_VIEW";
export const createUserView = (token, id) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/users/${id}/view`,
      method: "POST",
    })
      .then((res) => {
        dispatch(fireEvent(CREATE_USER_VIEW));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * bind phone or wechat
 */
export const BIND_BY_PHONE = "BIND_BY_PHONE";
export const BIND_BY_WECHAT = "BIND_BY_WECHAT";
export const UNBIND_BY_WECHAT = "UNBIND_BY_WECHAT";

export const bindByPhone = (token, { phone, auth_code }) => {
  return (dispatch) => {
    dispatch(openRequest(BIND_BY_PHONE));
    authRequest(token, {
      url: "/profile/bind/phone",
      method: "POST",
      data: {
        phone: {
          phone,
          auth_code,
        },
      },
    })
      .then((res) => {
        dispatch(closeRequest(BIND_BY_PHONE));
        dispatch(getUserInfo(token));
      })
      .catch((err) => {
        dispatch(closeRequest(BIND_BY_PHONE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const bindByWechat = (token, { code }) => {
  return (dispatch) => {
    dispatch(openRequest(BIND_BY_WECHAT));
    authRequest(token, {
      url: "/profile/bind/wechat",
      method: "POST",
      data: {
        wechat: {
          code,
        },
      },
    })
      .then((res) => {
        dispatch(closeRequest(BIND_BY_WECHAT));
        dispatch(getUserInfo(token));
      })
      .catch((err) => {
        dispatch(closeRequest(BIND_BY_WECHAT));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const unbindByWechat = (token) => {
  return (dispatch) => {
    dispatch(openRequest(UNBIND_BY_WECHAT));
    authRequest(token, {
      url: "/profile/bind/wechat",
      method: "DELETE",
      data: {},
    })
      .then((res) => {
        dispatch(closeRequest(UNBIND_BY_WECHAT));
        dispatch(showAppMessage("微信解绑成功", "success"));
        Router.reload();
      })
      .catch((err) => {
        dispatch(closeRequest(UNBIND_BY_WECHAT));
        if (err.error === "auth_phone_invalid") {
          dispatch(showAppMessage("解绑微信失败，请先绑定一个手机号", "error"));
        }
      });
  };
};

export const getUsersWithKey = (params) => {
  return request({
    url: "/users/search",
    params: params,
  }).then((res) => {
    const { users, pager } = res.data;
    return { users, pager };
  });
};

/**
 * applyGroupCheck
 */
export const applyGroupCheck = (token, id) => {
  return authRequest(token, {
    url: `/groups/${id}/check`,
  }).then((res) => {
    return res.data.viewed;
  });
};

/**
 * applayGroup
 */
export const applyCroup = (token, id) => {
  return authRequest(token, {
    url: `/groups/${id}/apply`,
    method: "POST",
  }).then((res) => res);
};
export const HANDLE_BAN_USER = "HANDLE_BAN_USER";
/**
 * mute user
 * @param {*} token
 * @param {*} userInfo
 */
export const banUser = (token, id, banParams, callback) => {
  return (dispatch) => {
    dispatch(openRequest(HANDLE_BAN_USER));
    authRequest(token, {
      url: `/users/${id}/ban`,
      method: "POST",
      data: banParams,
    })
      .then((res) => {
        dispatch(closeRequest(HANDLE_BAN_USER));
        dispatch(showAppMessage("已封禁", "success"));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const HANDLE_MUTE_USER = "HANDLE_MUTE_USER";
export const HANDLE_UNMUTE_USER = "HANDLE_UNMUTE_USER";
/**
 * mute user
 * @param {*} token
 * @param {*} userInfo
 */
export const muteUser = (token, id, banInfo, callback) => {
  return (dispatch) => {
    dispatch(openRequest(HANDLE_MUTE_USER));
    authRequest(token, {
      url: `/users/${id}/mute`,
      method: "POST",
      data: banInfo,
    })
      .then((res) => {
        dispatch(closeRequest(HANDLE_MUTE_USER));
        dispatch(showAppMessage("已禁言", "success"));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * unmute user
 * @param {*} token
 * @param {*} id
 */
export const unMuteUser = (token, id, callback) => {
  return (dispatch) => {
    dispatch(openRequest(HANDLE_UNMUTE_USER));
    authRequest(token, {
      url: `/users/${id}/mute`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(closeRequest(HANDLE_UNMUTE_USER));
        dispatch(showAppMessage("已取消禁言", "success"));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const HANDLE_DEPRECATE = "HANDLE_DEPRECATE";
export const deprecate = (token, callback) => {
  return (dispatch) => {
    dispatch(openRequest(HANDLE_DEPRECATE));
    authRequest(token, {
      url: "/profile/deprecate",
      method: "DELETE",
    })
      .then((res) => {
        dispatch(closeRequest(HANDLE_DEPRECATE));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const SET_USER_COMMENT_LIST = "SET_USER_COMMENT_LIST";
export const getUserComments = (params) => {
  return (dispatch) => {
    return request({
      url: `/users/${params.user_id}/comments`,
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_USER_COMMENT_LIST, list: res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};
