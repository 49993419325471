import { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fireEvent } from "~/redux/actions/app";
import { CHARGE_POST_SUBMIT, CHARGE_POST_SUCCESS } from "~/redux/actions/post";

import { Button, Menu, Row, Col } from "antd";
import BaseModal from "./BaseModal";
import eleIcon from "~/public/static/icons/ele@3x.png";

const PostChargeModal = (props) => {
  // hooks
  const [state, setState] = useState({ ele: 1, userEle: props.user.ele });

  // props
  const choices = [1, 2, 3, 4, 5, 6];

  // handles
  const handleSelect = (ele) => {
    setState({ ele: ele, userEle: props.user.ele });
  };
  const handleSubmit = () => {
    props.fireEvent(CHARGE_POST_SUBMIT, state);
  };
  const handleEvent = () => {
    const { name } = props.event;

    if (name === CHARGE_POST_SUCCESS) {
      props.hideModal();
    }
  };

  // events
  useEffect(() => handleEvent(), [props.event]);

  return (
    <BaseModal isOpen={props.isOpen} hideModal={props.hideModal} showLogo>
      <Menu mode="horizontal" selectedKeys={["charge"]}>
        <Menu.Item key="charge">充电</Menu.Item>
      </Menu>

      <div className="mb-20">
        <Row type="flex" align="middle">
          {choices.map((choice) => (
            <Col span={8} key={choice}>
              <div
                className={`choice ${
                  choice === state.ele
                    ? "selected"
                    : choice <= props.user.ele
                    ? ""
                    : "invisible"
                }`}
                onClick={() => handleSelect(choice)}
              >
                <span>{choice}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <Button type="primary" onClick={handleSubmit} block>
          给Ta充电
        </Button>
        <div
          style={{ padding: "0.5rem", textAlign: "center", color: "#999999" }}
        >
          {props.user.ele ? (
            <div>
              您的电量余额
              <span className="text-primary"> {props.user.ele}⚡️</span>
            </div>
          ) : null}
          <div style={{ fontSize: "0.8rem" }}>
            <a
              href="https://eleduck.com/posts/6GzfGe"
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              电量的作用及如何获取电量？
            </a>
          </div>
        </div>
        {/* <Button type='link' block>
          充值电量
        </Button> */}
      </div>

      <style jsx>
        {`
          .invite-hint {
            background: rgba(249, 186, 72, 0.14);
            font-size: 16px;
            line-height: 22px;
            padding: 11px 15px;
            width: 100%;
          }

          .choice {
            cursor: pointer;
            background: #f7f7f7;
            border-radius: 3px;
            border: 2px solid #f7f7f7;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 5px 5px 5px;
            text-align: center;
            font-size: 16px;
            color: #4a4a4a;
          }

          .choice.invisible {
            display: none;
          }

          .choice.selected {
            border-color: #f9ba48;
          }

          .choice span {
            padding-left: 20px;
            background-image: url(${eleIcon.src});
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 14px 14px;
          }

          .choice img {
            height: 14px;
          }
        `}
      </style>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  event: state.app.event,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fireEvent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostChargeModal);
