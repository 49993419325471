import {
  SET_MESSAGE_COUNT,
  SET_MESSAGE_LIST
} from '../actions/message'

const initialState = {
  messageCount: {
    category: {},
    kind: {},
    total: 0,
    count: 0,
  },
  messageList: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_COUNT:
      return {
        ...state,
        messageCount: action.count
      }
    case SET_MESSAGE_LIST:
      return {
        ...state,
        messageList: action.list
      }
    default:
      return state
  }
}
