import { useEffect } from "react";
import { Row, Col } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AppLogo from "~/components/app/AppLogo";
import SiteFootActive from "~/components/site/SiteFootActive";
import { getBlogrolls } from "~/redux/actions/app";
import {
  AndroidOutlined,
  AppleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const AppFooter = (props) => {
  const handleInit = () => {
    props.getBlogrolls();
  };

  const date = new Date();

  const year = date.getFullYear();

  // useEffect(() => handleInit(), []);

  return (
    <div className="app-footer">
      <div className="container">
        {props.blogrolls && props.blogrolls.length > 0 && (
          <Row>
            <div
              style={{
                marginBottom: "2rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginRight: "1rem",
                }}
              >
                合作伙伴
              </div>
              {props.blogrolls.map((blogroll) => (
                <a
                  key={blogroll.id}
                  className="link"
                  rel={`noreferrer ${blogroll.follow ? "" : "nofollow"}`}
                  href={blogroll.url}
                  target="blank"
                >
                  {blogroll.name}
                </a>
              ))}
            </div>
          </Row>
        )}

        <Row type="flex">
          <Col xs={24} md={8}>
            <div className="mb-20">
              <AppLogo gray />
              <p style={{ textAlign: "justify" }}>
                电鸭是国内最早的远程工作社区。我们倡导“只工作，不上班”的生活方式，努力推动多样化工作方式在国内的渐进式发展。
              </p>
            </div>
          </Col>
          <Col xs={0} md={1}>
            <div className="separator" />
          </Col>
          <Col xs={24} md={7}>
            <div className="mb-20">
              <nav>
                <a href="https://eleduck.com/posts/MZXfoe" target="_blank">
                  关于我们
                </a>
                <a href="https://eleduck.com/posts/6GzfGe" target="_blank">
                  社区规则
                </a>
                <a href="https://eleduck.com/posts/6yGflz" target="_blank">
                  加入我们
                </a>
                <a href="https://eleduck.com/posts/4lfXmX" target="_blank">
                  合作联系
                </a>
                <a
                  href="https://eleduck.com/feed/latest.xml"
                  style={{ display: "inline-flex", alignItems: "center" }}
                  target="_blank"
                >
                  <img width={10} height={10} src="/static/icons/rss.png" />
                  <div className="ml-5 inline-block">RSS</div>
                  <QuestionCircleOutlined
                    className="ml-5"
                    onClick={() =>
                      window.open("https://eleduck.com/posts/z1f2jX", "_blank")
                    }
                  />
                </a>
              </nav>

              <p>
                © 2019-{year} <a href="https://eleduck.com">eleduck.com</a>
              </p>
              <p>
                版权所有 电鸭
                <a href="https://beian.miit.gov.cn/">陕ICP备19012888号-2</a>
              </p>
              <p
                style={{
                  float: "left",
                  height: "20px",
                  lineHeight: "20px",
                  color: "#939393",
                }}
              >
                <a
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019402000068"
                >
                  陕公网安备 61019402000068号
                </a>
              </p>
            </div>
          </Col>
          <Col xs={0} md={1}>
            <div className="separator" />
          </Col>
          <Col xs={0} md={7}>
            <SiteFootActive />
            <div
              style={{
                color: "#b6b6b6",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>下载 APP：</div>
              <AndroidOutlined
                style={{ cursor: "pointer", fontSize: "18px" }}
                onClick={() =>
                  window.open("https://www.pgyer.com/eleduck", "_blank")
                }
              />
              <AppleOutlined
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                  fontSize: "18px",
                }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/cn/app/%E7%94%B5%E9%B8%AD/id1536307730",
                    "_blank"
                  )
                }
              />
            </div>
          </Col>
        </Row>
      </div>
      <style jsx>
        {`
          .link {
            margin-right: 1rem;
          }
          .link:not(.ant-btn):visited {
            color: #9b9b9b;
          }
          .link:not(.ant-btn):link {
            color: #9b9b9b;
          }
          .link:not(.ant-btn):hover {
            color: #f9ba48;
          }

          .app-footer {
            background: #323639;
            padding-top: 38px;
            padding-bottom: 30px;
            color: #9b9b9b;
            font-size: 14px;
            margin-top: 30px;
          }

          .app-footer .logo {
            height: 26px;
          }

          .app-footer nav {
            line-height: 26px;
            vertical-align: baseline;
          }

          .app-footer nav a {
            color: #ffffff;
            font-size: 12px;
          }

          .app-footer nav a + a {
            margin-left: 20px;
          }

          .app-footer p {
            margin-top: 15px;
            margin-bottom: 0;
          }

          .app-footer p a {
            color: #9b9b9b;
          }

          .app-footer .separator {
            background: #4a4a4a;
            width: 1px;
            height: 100%;
            margin: 0 auto;
          }
        `}
      </style>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   blogrolls: state.app.blogrolls,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getBlogrolls,
//     },
//     dispatch
//   );

export default AppFooter;
