// react
import { useEffect } from "react";
import Router, { useRouter } from "next/router";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWechatSignature, setRedirect } from "~/redux/actions/app";
import { getMessageCount } from "~/redux/actions/message";
import { getPromos } from "~/redux/actions/app";

import Head from "next/head";
import { useModal } from "react-modal-hook";
import AppHeader from "~/components/app/AppHeader";
import AppFooter from "~/components/app/AppFooter";
import Layout from "~/components/antd/Layout";
import Content from "~/components/antd/Content";
import Sider from "~/components/antd/Sider";
import UserMutedAlert from "~/components/user/UserMutedAlert";
import UserContactAlert from "~/components/user/UserContactAlert";
import UserMessageAlert from "~/components/user/UserMessageAlert";
import PhoneSignupModal from "~/components/modal/PhoneSignupModal";
import PhoneSigninModal from "~/components/modal/PhoneSigninModal";
import WechatSigninModal from "~/components/modal/WechatSigninModal";
import WechatSignupModal from "~/components/modal/WechatSignupModal";
import PhoneResetModal from "~/components/modal/PhoneResetModal";
import InviteVerifyModal from "~/components/modal/InviteVerifyModal";
import InviteShareModal from "~/components/modal/InviteShareModal";
import ProfileEditModal from "~/components/modal/ProfileEditModal";
import PostChargeModal from "~/components/modal/PostChargeModal";
import TopUpModal from "~/components/modal/TopUpModal";
import NewJobModal from "~/components/modal/NewJobModal";
import TalentJobCreatedModal from "~/components/modal/TalentJobCreatedModal";
import PhoneBindModal from "~/components/modal/PhoneBindModal";
import WechatBindModal from "~/components/modal/WechatBindModal";
import WechatUnbindModal from "~/components/modal/WechatUnbindModal";
import RestrictedOperationModal from "~components/modal/RestrictedOperationModal";
// join city modal
import JoinCityModal from "~/components/city/JoinCityModal";
// mute user modal
import MuteUserModal from "~/components/modal/MuteUserModal";
import BanUserModal from "~/components/modal/BanUserModal";
import EleRewardModal from "~/components/modal/EleRewardModal";
import PostReportRecordsModal from "~/components/modal/PostReportRecordsModal";
// report modal
import ReportModal from "~/components/modal/ReportModal";
import ApplicationModal from "~/components/modal/ApplicationModal";

import { message, BackTop } from "antd";
import { showAppModal } from "~/redux/actions/app";

// utils
import { isWechatBrowser } from "~/utils/wechat";
import settings from "~/settings";
import moment from "moment";
import { generateTitleWithSuffix } from "../../utils/seo";
import { extractBaseUrl } from "../../utils/seo";

const MODALS = {
  PhoneSigninModal,
  PhoneSignupModal,
  PhoneResetModal,
  InviteVerifyModal,
  InviteShareModal,
  WechatSigninModal,
  WechatSignupModal,
  ProfileEditModal,
  PostChargeModal,
  TopUpModal,
  PhoneBindModal,
  WechatBindModal,
  WechatUnbindModal,
  JoinCityModal,
  MuteUserModal,
  BanUserModal,
  ReportModal,
  NewJobModal,
  TalentJobCreatedModal,
  EleRewardModal,
  ApplicationModal,
  RestrictedOperationModal,
  PostReportRecordsModal,
};

const modals = {};

const SiteLayout = (props) => {
  const router = useRouter();

  const keywords =
    "电鸭社区,专注远程办公及招聘,远程办公从电鸭开始,远程工作,自由职业,兼职外包,在家工作,remote working,remotely,freelancer,";

  // 判断是否为详情页，主要用于设置logo是否用h1显示
  const isPost = props.isPost || false;

  const { token, messageCount } = props;

  const pageTitle = generateTitleWithSuffix(props.pageTitle);

  const registerModal = (modalName, Modal) => {
    const [showModal, hideModal] = useModal(() => {
      return <Modal hideModal={hideModal} isOpen />;
    });

    modals[modalName] = {
      showModal,
      hideModal: () => {
        hideModal();
        modals.current = null;
      },
    };
  };

  for (const modalName in MODALS) {
    registerModal(modalName, MODALS[modalName]);
  }

  // handles
  const handleCloseDrawler = () => {
    document.body && document.body.removeAttribute("style");
  };

  const handleRouteChange = () => {
    // fixed change page to scroll top
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (isWechatBrowser()) {
      const url = window.location.href.toString();

      window.wx.ready(() => {
        const shareData = {
          title: pageTitle,
          desc: "",
          link: url,
          imgUrl: `${settings.appUrl}/static/icon-500.png`,
        };
        window.wx.updateAppMessageShareData(shareData);
        window.wx.updateTimelineShareData(shareData);
      });

      getWechatSignature(url).then((data) => {
        window.wx.config({
          appId: settings.wxAppId,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.signature,
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
        });
      });
    }
  };

  useEffect(() => {
    const modalName = props.modal;
    if (modalName) {
      modals.current && modals.current.hideModal();
      modals.current = modals[modalName];
      modals.current.showModal();
      props.showAppModal(null);
    }
  }, [props.modal]);

  useEffect(() => {
    if (props.message) {
      message[props.message.level](props.message.text);
    }
  }, [props.message]);

  useEffect(() => {
    if (props.redirectUrl) {
      props.setRedirect(null);
      Router.push(props.redirectUrl);
    }
  }, [props.redirectUrl]);

  useEffect(() => {
    if (
      props.user &&
      !props.user.detailed &&
      moment(props.user.created_at).isAfter("2019-11-30")
    ) {
      props.showAppModal("ProfileEditModal");
    }
  }, [props.user]);

  useEffect(() => {
    handleRouteChange();
    handleCloseDrawler();

    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    props.getPromos();
    token && props.getMessageCount(token);
  }, []);

  return (
    <div>
      {!props.onlyBody && (
        <>
          <Head>
            <title key={"title"}>{pageTitle}</title>
            <meta
              key="description"
              name="description"
              content={
                props.description ||
                "电鸭社区是具有8年历史的远程工作招聘社区，也是远程办公互联网工作者们的聚集地。在社区，我们进行有价值的话题讨论，也分享远程、外包、零活、兼职、驻场等非主流工作机会。「只工作，不上班」是我们倡导的工作态度。"
              }
            />
            {props.keywords ? (
              <meta name="keywords" content={keywords + props.keywords} />
            ) : (
              <meta name="keywords" content={keywords} />
            )}

            <link
              rel="canonical"
              href={`${settings.appUrl}${extractBaseUrl(router.asPath)}`}
              key="canonical"
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            {settings.env === "production" && (
              <script
                key="baidu"
                dangerouslySetInnerHTML={{
                  __html: `
(function(){
    var bp = document.createElement('script');
    bp.defer = true;
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https') {
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    }
    else {
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bp, s);
})();        `,
                }}
              />
            )}
            {settings.env === "production" && settings.baiduAnalytic && (
              <script
                key="baidu-analytic"
                dangerouslySetInnerHTML={{
                  __html: `
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?${settings.baiduAnalytic}";
  hm.defer = true;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
        `,
                }}
              ></script>
            )}
            {/* {settings.env === "production" && (
          <script
            type="text/javascript"
            key="wwads"
            dangerouslySetInnerHTML={{
              __html: `
                (function(){
                var _sr = document.createElement('script');
                _sr.type = 'text/javascript';
                _sr.async = true;
                _sr.src = 'https://wwads.cn/code/install';
                (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild(_sr);
                })();
              `,
            }}
          ></script>
        )} */}
          </Head>
          {/* <div style={{ display: "none" }}>
        <img src="/static/icon-300.png" alt="logo" />
      </div> */}
          <AppHeader title={pageTitle} isPost={isPost} />
          <div className="container" style={props.style}>
            <UserMutedAlert user={props.user} />
            <UserContactAlert user={props.user} />
            <UserMessageAlert user={props.user} messageCount={messageCount} />
          </div>
          {props.pageHeader && props.pageHeader}
        </>
      )}

      <div
        className={props.largeContainer ? "large-container" : "container"}
        style={props.style}
      >
        <Layout hasSider={!!props.pageSider}>
          <Content>{props.children}</Content>
          {props.pageSider && <Sider>{props.pageSider}</Sider>}
          <div className="float-clear" />
        </Layout>
      </div>

      {!props.onlyBody && <AppFooter blogrolls={props.blogrolls} />}
      <BackTop />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  modal: state.app.modal,
  message: state.app.message,
  redirectUrl: state.app.redirectUrl,
  messageCount: state.message.messageCount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      setRedirect,
      getPromos,
      getMessageCount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteLayout);
