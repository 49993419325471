import { useState } from "react";
import { useRouter } from "next/router";
import BaseModal from "./BaseModal";
import { Menu, Form, Button, Checkbox, Input, AutoComplete } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { banUser } from "~/redux/actions/user";
import { onInputChange } from "~/utils/form";
import lscache from "lscache";
const { TextArea } = Input;

const Modal = (props) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const [reason, setReason] = useState();
  const dataSource = lscache.get("blockReasonHistory")
    ? lscache.get("blockReasonHistory")
    : [];

  const {
    query: { id },
  } = router;

  // props
  const { token } = props;

  const [state, setState] = useState({
    ban_ip: false,
    delete_user: false,
    delete_traces: false,
    reason: "",
  });

  const storeReason = () => {
    if (!lscache.get("blockReasonHistory")) {
      lscache.set("blockReasonHistory", [reason]);
    } else {
      let reasonArr = lscache.get("blockReasonHistory");
      if (reasonArr.indexOf(reason) === -1) {
        reasonArr.push(reason);
        lscache.set("blockReasonHistory", reasonArr);
      }
    }
  };

  const handleInputChange = onInputChange({ state, setState });

  const handleSubmit = async () => {
    storeReason();
    const values = await form.validateFields();

    props.banUser(token, id, { ...state, reason: values.reason }, () => {
      props.hideModal();
      router.reload();
    });
  };

  return (
    <BaseModal isOpen={props.isOpen} hideModal={props.hideModal} showLogo>
      <Menu mode="horizontal" selectedKeys={["mute"]}>
        <Menu.Item key="mute">用户封禁</Menu.Item>
      </Menu>

      <Form form={form} onFinish={handleSubmit} required layout="vertical">
        <p>确认要封禁该用户吗？</p>
        <div style={{ marginBottom: 10 }}>
          <Form.Item
            label="封禁理由"
            required
            hasFeedback
            name="reason"
            rules={[
              { required: true },
              {
                type: "string",
                transform: (value) => value.trim(),
              },
            ]}
          >
            <AutoComplete
              style={{ width: "100%" }}
              dataSource={dataSource}
              placeholder="封禁理由"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) => {
                setReason(value);
              }}
            />
          </Form.Item>
          <Checkbox
            checked={state.ban_ip}
            name="ban_ip"
            onChange={handleInputChange}
          >
            同时封禁 IP
          </Checkbox>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Checkbox
            checked={state.delete_traces}
            name="delete_traces"
            onChange={handleInputChange}
          >
            同时清除全部帖子和评论，此操作无法撤销
          </Checkbox>
        </div>
        <div style={{ marginBottom: 30 }}>
          <Checkbox
            checked={state.delete_user}
            name="delete_user"
            onChange={handleInputChange}
          >
            同时注销该用户，此操作无法撤销
          </Checkbox>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            确认封禁
          </Button>
        </Form.Item>
      </Form>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  viewUser: state.user.viewUser,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      banUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
