import {
  APPLY_TALENT_POST,
  CHECK_USER_RES,
  SET_APPLICATION_DATA,
  SET_STATUS_HISTORY,
  TALENT_INFO,
  CLEAR_STATUS_HISTORY,
} from "../actions/talent";

const initialState = {
  apply: null,
  checkUserResult: { detailed: false, applied: false },
  talentInfoResult: {
    detailed: false,
    apply_count: null,
    profile: null,
    progress: null,
  },
  applicationData: null,
  statusHistoryData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLY_TALENT_POST:
      return {
        ...state,
        apply: action.apply,
      };
    case CHECK_USER_RES:
      return {
        ...state,
        checkUserResult: action.res,
      };
    case TALENT_INFO:
      return {
        ...state,
        talentInfoResult: action.talentInfoResult,
      };
    case SET_APPLICATION_DATA:
      return {
        ...state,
        applicationData: action.applicationData,
      };
    case SET_STATUS_HISTORY:
      return {
        ...state,
        statusHistoryData: action.statusHistoryData,
      };
    case CLEAR_STATUS_HISTORY:
      return {
        ...state,
        statusHistoryData: null,
      };
    default:
      return state;
  }
};
