import _ from "lodash";

export const stripHtml = (str) =>
  str.replace(/<(?:.|\n)*?>/gm, "").replace(/\n/g, "");

export const extractBaseUrl = (url) => url.replace(/\?.*$/, "");

export const extractCategoryTitle = (categories, categoryId, tags) => {
  const [tagMap, categoryMap] = [new Map(), new Map()];

  categories.map((c) => categoryMap.set(c.id, c.name));
  const categoryName = categoryMap.get(categoryId);

  categories.map((c) =>
    _.flatMap(c.tag_groups, (t) => t.tags).map((t) => tagMap.set(t.id, t.name))
  );

  const tagNames = tags
    .filter((t) => t !== 0)
    .map((tagId) => tagMap.get(tagId));

  const labels = [];
  if (categoryName) labels.push(categoryName);
  return labels.concat(tagNames).join(" - ");
};

export const extractDescriptionForPostList = (posts) => {
  return posts
    .filter((p) => !(p.pinned || p.pinned_in_category))
    .map((p) => p.title)
    .slice(0, 10)
    .join("、");
};

export const generateTitleWithSuffix = (title) =>
  title
    ? `${title} | 电鸭社区`
    : "电鸭社区-专注远程工作招聘交流-远程工作，从电鸭开始";
