import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BaseModal from './BaseModal'
import AuthCodeFormItem from '../forms/AuthCodeFormItem'
import { Form, Input, Button, Menu } from 'antd'
import { MobileOutlined, LockOutlined } from '@ant-design/icons'

import {
  RESET_BY_PHONE,
  resetByPhone
} from '~/redux/actions/user'
import {
  showAppModal
} from '~/redux/actions/app'
import { onInputChange } from '~/utils/form'

const PhoneSignupModal = props => {
  const [state, setState] = useState({
    phone: '',
    code: '',
    password: ''
  })

  const handleInputChange = onInputChange({ state, setState })
  const handleFormSubmit = () => {
    props.resetByPhone({
      phone: `+86${state.phone}`,
      code: state.code,
      password: state.password
    })
  }
  const handleGoBack = event => {
    props.showAppModal('PhoneSigninModal')
    event.preventDefault()
  }

  const formDisabled = props.request[RESET_BY_PHONE]

  useEffect(() => {
    if (props.token) {
      props.hideModal()
    }
  }, [props.token])

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo
    >
      <Menu mode='horizontal' selectedKeys={['reset']}>
        <Menu.Item key='reset'>重置密码</Menu.Item>
      </Menu>

      <Form onFinish={handleFormSubmit}>
        <Form.Item>
          <Input
            name='phone'
            placeholder='手机号码'
            prefix={<MobileOutlined />}
            value={state.phone}
            onChange={handleInputChange}
          />
        </Form.Item>

        <AuthCodeFormItem
          code={state.code}
          phone={state.phone}
          onChange={handleInputChange}
        />

        <Form.Item>
          <Input
            name='password'
            type='password'
            placeholder='新密码'
            prefix={<LockOutlined />}
            value={state.password}
            onChange={handleInputChange}
          />
        </Form.Item>

        <div className='mb-20'>
          <Button
            type='primary'
            htmlType='submit'
            disabled={formDisabled}
            block
          >
            确定
          </Button>
        </div>

        <div className='text-center'>
          <a href='' className='text-primary' onClick={handleGoBack}>返回登录</a>
        </div>
      </Form>

      <style jsx global>{`
        .modal-logo {
          display: block;
          margin: 20px auto;
          height: 38px;
        }

        .navs {
          margin:  0;
          margin-bottom: 15px;
          padding: 0;
          list-style: none;
          display: table;
          table-layout: fixed;
          width: 100%;
          text-align: center;
          overflow: hidden;
          border-bottom: 2px solid #D8D8D8;
        }

        .navs li {
          display: table-cell;
        }

        .navs li a {
          display: block;
          text-decoration: none;
          color: #9B9B9B;
        }

        .navs li a span {
          display: block;
          padding: 18px 18px;
          line-height: 25px;
        }

        .navs li.active a {
          color: #000000;
        }

        .navs li.active a::after {
          display: block;
          line-height: 0;
          content: ' ';
          width: 40%;
          margin: 0 auto;
          border: 2px solid #F9BA48;
        }

        .form-group {
          padding: 10px;
          display: flex;
        }

        .form-group-policy {
          color: #F9BA48;
          font-size: 14px;
        }


        .form-control {
          flex: 1;
          font-size: 16px;
          line-height: 22px;
          padding: 11px 16px;
          border-radius: 2px;
          border: 1px solid rgba(216,216,216,1);
        }
      `}
      </style>
    </BaseModal>
  )
}

const mapStateToProps = (state) => ({
  request: state.app.request,
  token: state.user.token
})

const mapDispatchToProps = dispatch => bindActionCreators({
  resetByPhone,
  showAppModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PhoneSignupModal)
