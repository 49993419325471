import { Button } from "antd";
import { authUrl } from "~/utils/wechat";
const WechatAuthButton = ({ buttonText, payload, kind }) => {
  return (
    <a href={authUrl(kind, payload)}>
      <Button type="primary" block>
        {buttonText}
      </Button>
    </a>
  );
};
export default WechatAuthButton;
