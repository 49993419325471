import { combineReducers } from "redux";

import userReducer from "./user";
import appReducer from "./app";
import postReducer from "./post";
import commentReducer from "./comment";
import messageReducer from "./message";
import searchReducer from "./search";
import talentReducer from "./talent";

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  post: postReducer,
  comment: commentReducer,
  message: messageReducer,
  search: searchReducer,
  talent: talentReducer,
});

export default rootReducer;
