import { useState } from 'react'

import Tag from '~/components/tag/Tag'
import { message } from "antd";
// utils
import _ from 'lodash'

const dict = {
  'skill_type_tags': '专业领域',
  'work_type_tags': '职业身份',
}

export default props => {
  // hooks
  const [state, setState] = useState({
    selected: _.chain([]).concat(props.selected).compact().value()
  })

  // handles
  const fireChange = selected => {
    if (!props.onChange) return

    props.onChange({
      target: {
        value: selected,
        name: props.name
      }
    })
  }

  const handleSelect = tag => {
    let selected = _.clone(state.selected)
    if (props.tagGroup.multiple) {
      if (_.includes(selected, tag.id)) {
        _.pull(selected, tag.id)
      } else if (props.limit === 1) {
        selected = [tag.id]
      } else if (selected.length >= props.limit) {
        message.warning(`${dict[props.name]}最多只能添加${props.limit}个标签`);
        return;
      } else {
        selected.push(tag.id)
      }
    } else {
      selected = [tag.id]
    }

    setState({ selected })
    fireChange(selected)
  }

  return (
    <div className='line-height-40'>
      {props.tagGroup && props.tagGroup.tags.map((tag) => (
          <Tag
            key={tag.id}
            selected={_.includes(state.selected, tag.id)}
            onClick={() => handleSelect(tag)}
          >
            {tag.name}
          </Tag>
        ))}
    </div>
  )
}
