// react
import { useState, useEffect } from "react";

// view
import BaseModal from "./BaseModal";
import { Menu, Form, Radio, Button, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import TagGroup from "~/components/tag/TagGroup";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchTagGroups } from "~/redux/actions/app";
import { updateUserInfo } from "~/redux/actions/user";
import { getLevels } from "~/redux/actions/app";

// views
import MaleIcon from "~/public/static/icons/male.svg";
import FemaleIcon from "~/public/static/icons/female.svg";
import Image from "next/image";

// utils
import _ from "lodash";
import { onInputChange } from "~/utils/form";

const Modal = (props) => {
  // props
  const { user } = props;
  const skillTypeGroup = () => _.find(props.tagGroups, { code: "skill_type" });
  const workTypeGroup = () => _.find(props.tagGroups, { code: "work_type" });
  const levelTagNumber = _.find(props.levels, { level: user.level_info.level });

  // hooks
  const [state, setState] = useState({
    nickname: user.nickname,
    gender: user.gender,
    skill_type_tags: null,
    work_type_tags: null,
    contact_info: user.contact_info,
    ready: false,
  });

  // handles
  const handleInit = () => {
    props.getLevels();
    props.fetchTagGroups();
  };
  const handleUserDetailed = () => {
    if (user.detailed) {
      props.hideModal();
    }
  };
  const handleTagGroups = () => {
    if (props.tagGroups) {
      const userTagIds = _.map(user.tags, "id");
      const skillTypeTags = _.map(skillTypeGroup().tags, "id");
      const workTypeTags = _.map(workTypeGroup().tags, "id");

      setState({
        ...state,
        skill_type_tags: _.intersection(skillTypeTags, userTagIds),
        work_type_tags: _.intersection(workTypeTags, userTagIds),
        ready: true,
      });
    }
  };
  const handleInputChange = onInputChange({ state, setState });
  const handleSubmit = () => {
    props.updateUserInfo(props.token, {
      nickname: state.nickname,
      gender: state.gender,
      contact_info: state.contact_info,
      tag_ids: _.chain([])
        .concat(state.skill_type_tags, state.work_type_tags)
        .compact()
        .value(),
    });
  };

  // events
  useEffect(() => handleUserDetailed(), [props.user]);
  useEffect(() => handleTagGroups(), [props.tagGroups]);
  useEffect(() => handleInit(), []);

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      className="modal-profile-edit"
      noClose
      showLogo
    >
      <Menu mode="horizontal" selectedKeys={["edit"]}>
        <Menu.Item key="edit">完善信息</Menu.Item>
      </Menu>

      <Form onFinish={handleSubmit}>
        <Form.Item>
          <Input
            name="nickname"
            placeholder="您的昵称"
            prefix={<UserOutlined />}
            value={state.nickname}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item>
          <Radio.Group
            defaultValue={state.gender}
            buttonStyle="solid"
            className="radio-gender"
            name="gender"
            onChange={handleInputChange}
          >
            <Radio.Button value="male">
              <div className="gender-icon">
                <Image src={MaleIcon} width={26} height={26} />男
              </div>
            </Radio.Button>
            <Radio.Button value="female">
              <div className="gender-icon">
                <Image src={FemaleIcon} width={26} height={26} />女
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="联系方式" required>
          <Input
            name="contact_info"
            value={state.contact_info}
            onChange={handleInputChange}
          />
          <div className="text-sm text-secondary line-height-40">
            邮箱或微信号都可以。只显示给对你感兴趣的人或工作机会，请放心填写。
          </div>
        </Form.Item>

        {state.ready && props.levels && (
          <div>
            <Form.Item label="专业领域">
              <TagGroup
                name="skill_type_tags"
                tagGroup={skillTypeGroup()}
                selected={state.skill_type_tags}
                limit={levelTagNumber.job_tag_number}
                onChange={handleInputChange}
              />
            </Form.Item>

            <Form.Item label="职业身份">
              <TagGroup
                name="work_type_tags"
                tagGroup={workTypeGroup()}
                selected={state.work_type_tags}
                limit={levelTagNumber.identity_tag_number}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!props.tagGroups}
            block
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  tagGroups: state.app.tagGroups,
  levels: state.app.levels,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTagGroups,
      updateUserInfo,
      getLevels,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
