import _ from 'lodash'

const Checkbox = props => {
  const checkboxId = _.uniqueId('-checkbox-')

  return (
    <div>
      <input
        type='checkbox'
        id={checkboxId}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange} />
      <label htmlFor={checkboxId}>{props.children}</label>

      <style jsx>{`
        input {
          display: none;
        }

        input + label {
          cursor: pointer;
          display: inline-block;
          height: 20px;
          background-image: url(/static/icons/check.png);
          background-repeat: no-repeat;
          background-position: left center;
          filter: grayscale(100%);
          padding-left: 20px;
          vertical-align: middle;
          font-size: 14px;
        }

        input:checked + label {
          background-image: url(/static/icons/check.png);
          filter: none;
        }
      `}</style>
    </div>
  )
}

export default Checkbox
