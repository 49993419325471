import settings from "~/settings";
import qs from "querystringify";

const AUTH_URL = "https://open.weixin.qq.com/connect/oauth2/authorize";

export const authUrl = (type, params) => {
  const redirectQuery = qs.stringify(params);
  const redirectUrl = `${settings.appUrl}/auth/wechat/${type}?${redirectQuery}`;

  const query = qs.stringify({
    appid: settings.wxAppId,
    redirect_uri: redirectUrl,
    response_type: "code",
    scope: "snsapi_userinfo",
    state: "STATE",
    // forcePopup: true,
  });

  return `${AUTH_URL}?${query}#wechat_redirect`;
};

/**
 * 生成静默请求微信的openid的url。
 * @param {String} redirectUrl 回调url
 */
export const authUrlSilently = (redirectUrl) => {
  const query = qs.stringify({
    appid: settings.wxAppId,
    redirect_uri: redirectUrl,
    response_type: "code",
    scope: "snsapi_base",
    state: "STATE",
  });

  return `${AUTH_URL}?${query}#wechat_redirect`;
};

export const isWechatBrowser = () => {
  try {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf("micromessenger") !== -1;
  } catch (e) {
    return false;
  }
};
