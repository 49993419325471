import { useState } from "react";
import BaseModal from "./BaseModal";
import { Menu, Form, Button, Select, InputNumber, Input } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  createEleRewardedRecords,
  fetchEleRecords,
  showAppMessage,
} from "../../redux/actions/app";
import { getUsersWithKey } from "../../redux/actions/user";

const { TextArea } = Input;
const { Option } = Select;

const Modal = (props: any) => {
  const [users, setUsers] = useState<any>();
  const [userId, setUserId] = useState<any>(null);
  const [ele, setEle] = useState<number>(2);
  const [reason, setReason] = useState<string>();
  const [options, setOptions] = useState<Array<any>>([]);
  const [submitting, setSubmitting] = useState(false);

  const { token } = props;

  const onSearch = (value: any) => {
    value &&
      getUsersWithKey({ q: value }).then((res: any) => {
        setUsers(res);
      });
  };
  const handleChange = (value: any, option: any) => {
    setUserId(value);
  };

  const handleSubmit = () => {
    if (!userId) {
      props.showAppMessage("请选择用户", "error");
      return;
    }
    setSubmitting(true);
    props.createEleRewardedRecords(
      token,
      {
        userId,
        ele,
        reason,
      },
      () => {
        props.hideModal();
        props.fetchEleRecords(token, 1);
        setSubmitting(false);
      }
    );
  };

  return (
    <BaseModal isOpen={props.isOpen} hideModal={props.hideModal}>
      <Menu mode="horizontal" selectedKeys={["mute"]}>
        <Menu.Item key="mute">电量奖励</Menu.Item>
      </Menu>

      <Form className="mt-50" onFinish={handleSubmit}>
        <Form.Item label="用户">
          <Select
            autoClearSearchValue
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder="输入用户昵称、ID 或 hashid"
            optionLabelProp="label"
            optionFilterProp="label"
            onChange={handleChange}
            onSearch={onSearch}
          >
            {users &&
              users.users &&
              users.users.map((user: any) => (
                <Option
                  key={user.hashid}
                  value={user.hashid}
                  label={user.nickname + `（${user.hashid}）`}
                >
                  <div key={user.hashid} className="flex align-items-center">
                    <img
                      src={user.avatar_url}
                      width={40}
                      height={40}
                      style={{ borderRadius: "5px" }}
                    />
                    <div className="mx-10">
                      <div>{user.nickname}</div>
                      <div style={{ color: "#5c6064" }}>ID:{user.hashid}</div>
                    </div>
                  </div>
                </Option>
              ))}
            {options}
          </Select>
        </Form.Item>
        <Form.Item label="电量">
          <InputNumber
            min={1}
            value={ele}
            onChange={(value: any) => setEle(value)}
          />
        </Form.Item>
        <Form.Item label="理由">
          <TextArea
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block disabled={submitting}>
            {submitting ? "添加中..." : "添加"}
          </Button>
        </Form.Item>
      </Form>
    </BaseModal>
  );
};

const mapStateToProps = (state: any) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createEleRewardedRecords,
      showAppMessage,
      fetchEleRecords,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
