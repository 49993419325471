// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// nextjs

import Link from 'next/link'

//antd
import { Menu } from 'antd'

//components
import BaseModal from '~/components/modal/BaseModal'

const JoinCityModal = props =>{
    const { level } = props.user.level_info

    return (
        <BaseModal
            isOpen={props.isOpen}
            hideModal={props.hideModal}
        >
            <img className='modal-logo' src='/static/city-logo.png' alt="logo" />
            <Menu mode='horizontal' selectedKeys={['title']} className='ant-menu-city' items={[{ label: level<3?'温馨提示':'扫码入群', key: 'title' }]} />
            {
                (level < 3) ? (
                <div>
                    <div className='notice-text'>遗憾鸭，你现在是{level}级，需要达到3级后才能加入该群哦</div>,
                    <div className='notice-help'>
                        <a href='https://eleduck.com/posts/6GzfGe' className='notice-text'>如何快速提升等级?</a>
                    </div>
                </div>):(<div>
                    <img className='qrcode-img' src='/static/city-qrcode.png' alt="二维码" />,
                    <div className='qrcode-remark'>加群管理员好友，备注[城市]</div>
                </div>)
            }
            
        </BaseModal>
    )

}

const mapStateToProps = (state) => ({
    token: state.user.token,
    user: state.user.user
  })
  
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
  
export default connect(mapStateToProps, mapDispatchToProps)(JoinCityModal)