export default (props) => {
  return (
    <span className={props.selected ? "selected" : ""} onClick={props.onClick}>
      {props.children}
      <style jsx>
        {`
          span {
            color: #877d6a;
            font-size: 14px;
            padding: 3px 11px;
            background: #f7f7f7;
            border-radius: 4px;
            margin-right: 15px;
            cursor: pointer;
            display: inline-block;
            line-height: 20px;
            margin-top: 5px;
          }

          .selected {
            background: #f3f0dd;
          }
        `}
      </style>
    </span>
  );
};
