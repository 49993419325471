import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import BaseModal from "./BaseModal";
import { FormOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { showAppModal, showAppMessage, setReport } from "~/redux/actions/app";

import { postReport } from "~/redux/actions/user";

const TalentJobCreatedModal = (props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo={false}
    >
      <div style={{ color: "#9b9b9b", paddingTop: 30, textAlign: "center" }}>
        <ul style={{ textAlign: "left", marginBottom: 0 }}>
          <li>
            当前职位为隐藏状态，待管理员审核通过后，会正式发布到人才库，开始推荐
          </li>
          <li>
            24小时内我们会添加您的微信；您也可以主动联系管理员@大灰的微信。
          </li>
        </ul>
        <img
          style={{ marginTop: 30 }}
          width="200"
          src="/static/WechatIMG609.jpg"
        />
        <div>{'添加时请备注"人才库"'}</div>
        <Button
          style={{ marginTop: 30 }}
          type="primary"
          icon={<FormOutlined />}
          onClick={() => props.hideModal()}
          block
        >
          确定
        </Button>
      </div>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  report: state.app.report,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      showAppMessage,
      setReport,
      postReport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalentJobCreatedModal);
