// react
import { useRouter } from "next/router";

// comps
import { Card, Button } from "antd";
import { FormOutlined } from "@ant-design/icons";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAppModal } from "~/redux/actions/app";

const NewPostCard = (props) => {
  const router = useRouter();

  const handleLinkToNewPost = () => {
    if (props.token) {
      router.push("/posts/new");
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };

  const handleLinkToNewJob = () => {
    if (props.token) {
      props.showAppModal("NewJobModal");
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };

  return (
    <Card className={props.className}>
      <Button
        type="primary"
        icon={<FormOutlined />}
        onClick={handleLinkToNewJob}
        block
      >
        发布招聘
      </Button>
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        icon={<FormOutlined />}
        onClick={handleLinkToNewPost}
        block
      >
        发布话题
      </Button>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewPostCard);
