import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import settings from '~/settings'

import BaseModal from './BaseModal'
import { Menu, Form, Row, Col, Button, Input, message } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'

const mapStateToProps = (state) => ({
  user: state.user.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const code = props.user.invite_code
  const link = `${settings.appUrl}/invite?code=${code}`

  const handleCodeCopy = () => {
    message.success('邀请码已复制')
  }

  const handleLinkCopy = () => {
    message.success('邀请链接已复制')
  }

  const modalHeader = (
    <div className='invite-header'>
      <img src='/static/invite-header.png' alt="邀请函" />
      <h3>分享是最好的支持</h3>
      <p>当朋友通过你的邀请链接注册，你和他都将同时获得5个电量</p>
      <style jsx>{`
        .invite-header {
          height:296px;
          background:linear-gradient(180deg,rgba(251,210,73,1) 0%,rgba(245,166,35,1) 100%);
          border-radius:2px;
          text-align: center;
          padding-top: 30px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .invite-header img {
          display: block;
          width: 170px;
          height: 156px;
          margin-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
        }

        .invite-header h3 {
          font-size:24px;
          color:#fff;
          line-height:33px;
          margin-bottom: 5px;
        }

        .invite-header p {
          font-size:16px;
          color:#fff;
          line-height:22px;
        }
      `}
      </style>
    </div>
  )

  return (
    <BaseModal
      header={modalHeader}
      isOpen={props.isOpen}
      className='modal-invite-share'
      hideModal={props.hideModal}
    >
      <Menu mode='horizontal' selectedKeys={['invite']}>
        <Menu.Item key='invite'>下面是你的专属邀请码和邀请链接</Menu.Item>
      </Menu>

      <Form>
        <Form.Item>
          <label className='text-secondary'>我的邀请码</label>
          <Row gutter={16}>
            <Col xs={12} sm={16}>
              <Input value={code} readOnly />
            </Col>
            <Col xs={12} sm={8}>
              <CopyToClipboard
                text={code}
                onCopy={handleCodeCopy}
              >
                <Button
                  type='primary'
                  style={{ height: '44px' }}
                  block
                >
                  点击复制
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </Form.Item>

        <div
          style={{
            borderBottom: '2px dashed #CBCBCB',
            paddingTop: '10px',
            marginBottom: '10px'
          }}
        />

        <Form.Item>
          <label className='text-secondary'>我的邀请链接</label>

          <Input value={link} readOnly />

          <CopyToClipboard
            text={link}
            onCopy={handleLinkCopy}
          >
            <Button
              type='primary'
              className='mt-20'
              style={{ height: '44px' }}
              block
            >
              点击复制链接
            </Button>
          </CopyToClipboard>
        </Form.Item>
      </Form>

      <style jsx global>{`
        .modal-invite-share .close {
          position: absolute;
          right: 25px;
          top: 10px;
          float: none;
          margin: 0;
          color: #fff;
        }

        .modal-invite-share .modal-body {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      `}
      </style>
    </BaseModal>
  )
})
