// react
import { useState } from 'react'
import { useRouter } from 'next/router'

// view
import BaseModal from './BaseModal'
import { Menu, Form, Button, Input } from 'antd'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { muteUser } from '~/redux/actions/user'

// utils
import _ from 'lodash'
import { onInputChange } from '~/utils/form'

const Modal = props => {

  const router = useRouter()

  const { query: {id}} = router

  // props
  const { token } = props

  const [state, setState] = useState({
    ban_days: '',
    ban_reason: ''
  })
  
  const handleInputChange = onInputChange({ state, setState })

  const handleSubmit = () => {
    props.muteUser(token, id, state, () => {
      props.hideModal()
      router.reload()
    })
  }

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo
    >
      <Menu mode='horizontal' selectedKeys={['mute']}>
        <Menu.Item key='mute'>用户禁言</Menu.Item>
      </Menu>

      <Form onFinish={handleSubmit} required layout="vertical">
        <Form.Item label='禁言天数' required>
          <Input
            name='ban_days'
            placeholder='请输入禁言天数'
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item label='禁言原因' required>
          <Input
            name='ban_reason'
            placeholder='请输入禁言原因'
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </BaseModal>
  )
}

const mapStateToProps = (state) => ({
  viewUser: state.user.viewUser,
  token: state.user.token
})

const mapDispatchToProps = dispatch => bindActionCreators({
  muteUser
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
