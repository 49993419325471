// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from "js-cookie";
import cookieParser from "cookie";
import settings from "../settings";

const domain =
  process.env.NODE_ENV === "development" ? undefined : settings.domain;

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 7,
      path: "/",
      domain,
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, { expires: 1 });
    cookie.remove(key, { expires: 1, domain });
  }
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

export const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (req.headers.cookie) {
    const cookies = cookieParser.parse(req.headers.cookie);
    return cookies[key];
  } else {
    return undefined;
  }
};
