import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button } from 'antd'

import BaseModal from './BaseModal'
import {unbindByWechat} from '~/redux/actions/user';

const Modal = props => {
  const handleHideModal = () => {    
    props.hideModal();
  }

  const handleUnbindWechat = () => {
    props.unbindByWechat(props.token);
    handleHideModal();
  };

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={handleHideModal}
      showLogo
    >
      <div className="text-center wechat-unbind-modal">
        <h2>解绑微信</h2>
        <p>确定要解绑微信吗？</p>
        <div className="wechat-unbind-modal__footer">
          <Button onClick={handleHideModal} htmlType='submit' block>
            取消
          </Button>
          <Button onClick={handleUnbindWechat} type='primary' htmlType='submit' block>
            确定
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

const mapStateToProps = (state) => ({
  token: state.user.token,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  unbindByWechat,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
