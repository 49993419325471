import axios from "axios";
import httpAdapter from "axios/lib/adapters/http";
import settings from "~/settings";
import _ from "lodash";

const axiosInstance = axios.create({
  baseURL: `${settings.apiUrl}/api/v1`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    if (err.response) {
      err.statusCode = err.response.status;
      const { data } = err.response;
      if (data && data.message) {
        err.data = data;
        err.message = data.message;
        err.error = data.error;
      }
    }

    return Promise.reject(err);
  }
);

export function request(options = {}) {
  return axiosInstance(options);
}

export function authRequest(token, options = {}) {
  const headers = _.assign({}, options.headers);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axiosInstance({
    ...options,
    headers: headers,
  });
}
