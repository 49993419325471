import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BaseModal from './BaseModal'
import { Button } from 'antd'
import QRCode from 'qrcode.react'

import {
  showAppModal,
  showAppMessage
} from '~/redux/actions/app'
import {
  createWechatToken,
  checkUserInfo
} from '~/redux/actions/user'

import { authUrl, isWechatBrowser } from '~/utils/wechat'

const Modal = props => {
  let timer = null

  const createToken = () => {
    props.createWechatToken()
  }

  const checkWechatOpenId = () => {
    if (!props.wechatToken || props.wechatTokenExpired) {
      return
    }

    if (timer) {
      clearTimeout(timer)
    }

    props.checkUserInfo(props.token , () => {
      timer = setTimeout(checkWechatOpenId, 1000)
    })
  }

  const handleHideModal = () => {    
    props.hideModal()
    clearTimeout(timer)
  }

  useEffect(() => {
    createToken()

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (props.wechatToken) {
      checkWechatOpenId()
    }
  }, [props.wechatToken])

  useEffect(() => {
    if (props.user.wechat_openid) {
      props.showAppMessage('绑定成功', 'success')
      handleHideModal()
    }
  }, [props.user])

  // helpers
  const getAuthUrl = () => {
    return authUrl('bind', {
      wechat_token: props.wechatToken,
      token: props.token
    })
  }

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={handleHideModal}
      showLogo
    >
      <div className='text-center'>
        {
          isWechatBrowser() ? (
            <div className='mb-15'>
              <a href={authUrl('bind')}>
                <Button type='primary' block>绑定微信</Button>
              </a>
            </div>
          ) : (
            <div className='mb-15'>
              <div className={props.wechatTokenExpired ? 'expired' : ''}>
                <QRCode value={getAuthUrl()} size={162} />
                <div className='refresh'>
                  已失效 <a className='text-primary' onClick={createToken}>刷新</a>
                </div>
              </div>

              <p className='mt-15 text-secondary'>微信扫一扫，快速绑定</p>
            </div>
          )
        }
      </div>

      <style jsx>{`
        .expired {
          width: 162px;
          height: 162px;
          margin: 0 auto;
          position: relative;
        }
        .refresh {
          position: absolute;
          display: none;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          line-height: 162px;
          background: rgba(153, 153, 153, 0.9);
        }
        .expired .refresh {
          display: block;
        }
      `}
      </style>
    </BaseModal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  wechatToken: state.user.wechatToken,
  wechatTokenExpired: state.user.wechatTokenExpired
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showAppModal,
  showAppMessage,
  createWechatToken,
  checkUserInfo  
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
