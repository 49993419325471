import { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Button, Menu, Input, Form } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import BaseModal from './BaseModal'

import { showAppModal } from '~/redux/actions/app'
import { verifyInvite } from '~/redux/actions/user'
import { onInputChange } from '~/utils/form'

const mapStateToProps = (state) => ({
  request: state.app.request,
  inviteCode: state.user.inviteCode,
  inviteValid: state.user.inviteValid
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showAppModal,
  verifyInvite
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const [state, setState] = useState({
    inviteCode: ''
  })

  const handleInputChange = onInputChange({ state, setState })
  const handleSubmit = () => {
    props.verifyInvite(state.inviteCode)
  }
  const handleVerified = () => {
    if (props.inviteValid) {
      props.showAppModal('PhoneSignupModal')
    }
  }

  useEffect(() => handleVerified(), [])
  useEffect(() => handleVerified(), [props.inviteValid])

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo
    >
      <Menu mode='horizontal' selectedKeys={['invite']}>
        <Menu.Item key='invite'>注册</Menu.Item>
      </Menu>

      <Form onFinish={handleSubmit}>
        <Form.Item>
          <div className='invite-hint'>
            电鸭社区目前针对拥有邀请码的用户进行注册
          </div>
        </Form.Item>

        <Form.Item>
          <Input
            name='inviteCode'
            placeholder='请输入您的邀请码'
            prefix={<UserOutlined />}
            value={state.inviteCode}
            onChange={handleInputChange}
          />

          <a
            className='text-primary'
            href='https://jinshuju.net/f/FHDPh6'
          >
            申请邀请码
          </a>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' block>
            确定
          </Button>
        </Form.Item>
      </Form>

      <style jsx>{`
        .invite-hint {
          background: rgba(249,186,72,0.14);
          font-size: 16px;
          line-height: 22px;
          padding: 11px 15px;
          width: 100%;
        }
      `}
      </style>
    </BaseModal>
  )
})
