import _ from 'lodash'

import {
  SET_POST_COMMENTS,
  RESET_COMMENTS,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  VOTE_COMMENT,
  UNVOTE_COMMENT,
  CHECK_COMMENT_FLAGS
} from '~/redux/actions/comment'

const initialState = {
  commentList: null,
  commentFlags: {
    voted: []
  }
}

const createComment = (commentList, comment) => {
  let comments = _.get(commentList, 'comments') || []
  let pager = _.get(commentList, 'pager')

  if (comment.ancestor_id) {
    comments = [...comments]
    const ancestor = _.find(comments, { id: comment.ancestor_id })
    ancestor.replies.push(comment)
  } else {
    comments = [...comments, comment]
  }

  return { comments, pager }
}

const deleteComment = (commentList, comment, deletedComment) => {
  let comments = _.get(commentList, 'comments') || []
  let pager = _.get(commentList, 'pager')

  comments = [...comments]
  if (comment.ancestor_id) {
    const ancestor = _.find(comments, { id: comment.ancestor_id })
    if (ancestor) {
      _.remove(ancestor.replies, { id: comment.id })
    }
  } else {
    const target = _.find(comments, { id: comment.id })
    if (target) {
      if (target.replies.length) {
        target.deleted = deletedComment.deleted
        target.deleted_mode = deletedComment.deleted_mode
      } else {
        _.remove(comments, { id: comment.id })
      }
    }
  }

  return { comments, pager }
}

const updateComment = (commentList, comment) => {
  let comments = _.get(commentList, 'comments') || []
  let pager = _.get(commentList, 'pager')

  comments = [...comments]
  let target = null
  if (comment.ancestor_id) {
    const ancestor = _.find(comments, { id: comment.ancestor_id })
    target = ancestor && _.find(ancestor.replies, { id: comment.id })
  } else {
    target = _.find(comments, { id: comment.id })
  }

  target && _.assign(target, {
    content: comment.content,
    raw_content: comment.raw_content,
    modified_at: comment.modified_at
  })

  return { comments, pager }
}

const concatComments = (commentList, list) => {
  let comments = _.get(commentList, 'comments') || []

  return {
    comments: [...comments, ...list.comments],
    pager: list.pager
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_COMMENTS:
      return {
        ...state,
        commentList: null
      }
    case SET_POST_COMMENTS:
      return {
        ...state,
        commentList: concatComments(state.commentList, action.list)
      }
    case CREATE_COMMENT:
      return {
        ...state,
        commentList: createComment(state.commentList, action.comment)
      }
    case UPDATE_COMMENT:
      return {
        ...state,
        commentList: updateComment(state.commentList, action.comment)
      }
    case DELETE_COMMENT:
      return {
        ...state,
        commentList: deleteComment(state.commentList, action.comment, action.deletedComment)
      }
    case VOTE_COMMENT:
      return {
        ...state,
        commentFlags: {
          ...state.commentFlags,
          voted: [...state.commentFlags.voted, action.id]
        }
      }
    case UNVOTE_COMMENT:
      return {
        ...state,
        commentFlags: {
          ...state.commentFlags,
          voted: (function () {
            const voted = _.clone(state.commentFlags.voted)
            _.pull(voted, action.id)
            return voted
          })()
        }
      }
    case CHECK_COMMENT_FLAGS:
      return {
        ...state,
        commentFlags: {
          ...state.commentFlags,
          ...action.flags
        }
      }
    default:
      return state
  }
}
