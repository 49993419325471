// react
import { useEffect } from "react";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  sendAuthCode,
  refreshAuthCodeText,
  SEND_AUTH_CODE,
} from "../../redux/actions/user";

// views
import { Form, Input, Button, Row, Col } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

// utils
import { recaptchaSiteKey } from "~/settings";

const AuthCodeFormItem = (props) => {
  // props
  let recaptchaRef = null;

  useEffect(() => {
    const timer = setInterval(() => {
      props.refreshAuthCodeText();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSendCode = (captcha) => {
    recaptchaRef.reset();
    props.sendAuthCode({
      phone: `+86${props.phone}`,
      captcha: captcha,
    });
  };

  const handleClick = () => {
    recaptchaRef.execute();
  };

  const codeDisabled = () => {
    return props.request[SEND_AUTH_CODE] || props.codeRemains > 0;
  };

  const codeButtonText = () => {
    return props.codeRemains > 0
      ? `${props.codeRemains}秒后重试`
      : "获取验证码";
  };

  return (
    <Form.Item>
      <Row gutter={8}>
        <Col xs={10} sm={14}>
          <Input
            placeholder="验证码"
            maxLength={6}
            name="code"
            value={props.code}
            onChange={props.onChange}
          />
        </Col>
        <Col xs={14} sm={10}>
          <Button
            type="primary"
            onClick={handleClick}
            disabled={codeDisabled()}
            block
          >
            {codeButtonText()}
          </Button>
        </Col>
      </Row>

      <ReCAPTCHA
        ref={(ref) => {
          recaptchaRef = ref;
        }}
        size="invisible"
        sitekey={recaptchaSiteKey}
        hl="zh-CN"
        onChange={handleSendCode}
      />
    </Form.Item>
  );
};

const mapStateToProps = (state) => ({
  codeRemains: state.user.codeRemains,
  request: state.app.request,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendAuthCode,
      refreshAuthCodeText,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AuthCodeFormItem);
