// import React from 'react'
import Modal from "react-modal";
import logo from "~/public/static/logo.png";

// Modal.setAppElement(document.body)

const STYLE = () => {
  if (typeof window !== "undefined" && window.innerWidth > 600) {
    return {
      overlay: {
        backgroundColor: "rgba(74, 74, 74, 0.75)",
        overflowY: "auto",
        zIndex: 1000,
      },
      content: {
        top: "80px",
        bottom: "none",
        margin: "0 auto",
        background: "rgba(255,255,255,1)",
        borderRadius: "none",
      },
    };
  } else {
    return {
      overlay: {
        position: "fixed",
        top: 0,
        left: "-6px",
        right: "-6px",
        bottom: 0,
        backgroundColor: "rgba(74, 74, 74, 0.20)",
      },
      content: {
        position: "absolute",
        top: "35%",
        left: "0px",
        right: "0px",
        bottom: "0px",
        background: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        boxShadow: "0px -15px 45px gray",
      },
    };
  }
};

export default (props) => {
  const modalLogo = <img className="modal-logo" src={logo.src} alt="logo" />;
  const noClose = props.noClose;

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.hideModal}
      onAfterOpen={props.onAfterOpen}
      className={props.className}
      style={STYLE()}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick || !noClose}
      shouldCloseOnEsc={props.shouldCloseOnEsc || !noClose}
      ariaHideApp={false}
    >
      {!noClose && (
        <span className="close" onClick={props.hideModal}>
          &times;
        </span>
      )}

      {props.header && props.header}

      <div className="modal-body">
        {props.showLogo && modalLogo}

        {props.children}
      </div>
    </Modal>
  );
};
