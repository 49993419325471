import { SET_SEARCH_POST_LIST, SET_SEARCH_KEYWORDS_LIST } from '../actions/search'

const initialState = {    
    searchPostList: null,
    keywordsList: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_POST_LIST:
          return {
            ...state,
            searchPostList: action.list
          }
        case SET_SEARCH_KEYWORDS_LIST:
            return {
              ...state,
              keywordsList: action.list
            }
        default:
            return state
    }

}