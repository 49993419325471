import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import BaseModal from "./BaseModal";
import { Menu, Form, Input, Radio, Button, Upload, Spin, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Loading from "~/components/app/Loading";

import { request, authRequest } from "~/utils/api";
import { showAppModal, showAppMessage } from "~/redux/actions/app";
import _ from "lodash";

const { TextArea } = Input;

const radioStyle = {
  display: "flex",
  paddingTop: "5px",
  paddingBottom: "5px",
};

const Modal = (props) => {
  const { report } = props;
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const kind = _.lowerFirst(props.report.contentType);
    request({ method: "GET", url: `/report_categories?kind=${kind}` }).then(
      (resp) => setCategories(resp.data.report_categories)
    );
  }, [props.report]);

  const handleSubmit = async () => {
    setSubmitting(true);

    const values = await form.validateFields();
    const formData = new FormData();
    formData.set("content_type", report.contentType);
    formData.set("content_id", report.contentId);
    formData.set("report_category_id", values.report_category_id);
    formData.set("report_note", values.report_note);
    files.forEach((file) => formData.append("files[]", file));

    authRequest(props.token, {
      url: "/report_contents",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        props.showAppMessage("举报成功", "success");
        props.hideModal();
      })
      .catch((err) => {
        props.showAppMessage(err.message, "error");
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo={false}
    >
      <Menu mode="horizontal" selectedKeys={["mute"]}>
        <Menu.Item key="mute">
          举报
          {report.contentType === "Post"
            ? "帖子"
            : report.contentType === "Comment"
            ? "评论"
            : "用户"}
        </Menu.Item>
      </Menu>

      {categories.length ? (
        <Form form={form} onFinish={handleSubmit} required layout="vertical">
          <Form.Item
            label="举报类型"
            name="report_category_id"
            initialValue={categories[0].id}
            required
            hasFeedback
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {categories.map((category) => (
                <Radio
                  style={radioStyle}
                  key={category.id}
                  value={category.id}
                  title={category.description}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: category.description }}
                  ></div>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="补充说明"
            extra="请说明必要的事实、过程，方便社区管理员明白怎么回事：）"
            required
            hasFeedback
            name="report_note"
            rules={[
              { required: true },
              {
                type: "string",
                min: 5,
                max: 500,
                transform: (value) => value.trim(),
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              onBlur={(e) =>
                form.setFieldValue("report_note", e.target.value.trim())
              }
            />
          </Form.Item>
          <Form.Item
            label="截图证明"
            name="files"
            extra="请提供必要的/相关的邮件/微信等截图，方便管理员核实判断并及时处理。只有附有截图的举报，才会得到社区的额外电量奖励（最多支持5张截图）"
          >
            <Upload
              accept="image/png, image/jpeg"
              listType="picture-card"
              maxCount={5}
              multiple={true}
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
              }}
              beforeUpload={() => false}
              onChange={({ fileList }) =>
                setFiles(fileList.map((x) => x.originFileObj))
              }
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitting}
              block
            >
              {submitting ? "提交中..." : "提交"}
            </Button>
          </Form.Item>
          <div style={{ fontSize: "11px", color: "#bdbdbd" }}>
            <ul style={{ paddingLeft: "10px" }}>
              <li>
                社区管理将在1-2个工作日内核实处理。通过后，您将2-10个电量补偿或奖励，
                <a
                  onClick={() =>
                    window.open("https://eleduck.com/posts/rdfE2B", "_blank")
                  }
                  style={{ color: "#f9ba48" }}
                >
                  点此查看详情。
                </a>
              </li>
              <li>
                机会难得，发帖不易。社区在尊重和保障大家举报权利的同时，也提醒大家凡事多换位思考，认真对待每次举报，勿频繁滥用/恶意举报。
              </li>
            </ul>
          </div>
        </Form>
      ) : (
        <Loading />
      )}
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  report: state.app.report,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      showAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
