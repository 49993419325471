import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPostReportList, getPostsWithKey } from "../../redux/actions/post";
import { getUsersWithKey } from "../../redux/actions/user";

import { Modal, Form, Select, Space, Table, Tag, Button } from "antd";

import { timeAgo } from "../../utils/app";

const { Option } = Select;

const REPORT_STATUS: any = {
  pending: "待审核",
  approved: "通过",
  declined: "拒绝",
};

const PostReportRecordsModal = (props: any) => {
  const [posts, setPosts] = useState<any>();
  const [users, setUsers] = useState<any>();
  const [options, setOptions] = useState<Array<any>>([]);
  const [postId, setPostId] = useState<any>(null);
  const [reports, setReports] = useState<any>(null);
  const columns: any = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "帖子",
      dataIndex: "post",
      key: "post",
      render: (post: any) => (
        <a href={`/posts/${post.id}`} target="_blank">
          {post.title}
        </a>
      ),
    },
    {
      title: "举报类型",
      dataIndex: "report_category",
      key: "report_category",
    },
    {
      title: "举报理由",
      dataIndex: "report_note",
      key: "report_note",
    },
    {
      title: "截图",
      dataIndex: "report_images",
      key: "report_images",
      render: (report_images: string[]) =>
        report_images.length > 0
          ? report_images.map((i: any) => (
              <a key={i} href={i} target="_blank" rel="noreferrer">
                <img src={i} width="100px" />
              </a>
            ))
          : "无",
    },
    {
      title: "举报人",
      dataIndex: "user",
      key: "user",
      render: (user: any) => (
        <a href={`/users/${user.id}`} target="_blank">
          {user.nickname}
        </a>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "pending") {
          return <Tag color="yellow">{REPORT_STATUS[status]}</Tag>;
        } else if (status === "approved") {
          return <Tag color="green">{REPORT_STATUS[status]}</Tag>;
        } else {
          return <Tag color="red">{REPORT_STATUS[status]}</Tag>;
        }
      },
    },
    {
      title: "举报时间",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];
  const onUserSearch = (value: any) => {
    value &&
      getUsersWithKey({ q: value }).then((res: any) => {
        setUsers(res);
      });
  };
  const handleUserChange = (value: any, option: any) => {
    setPostId(null);
    if (value) {
      getPostsWithKey({ q: value, user_id: value }).then((res: any) => {
        setPosts(res);
      });
    } else {
      setPosts(null);
    }
  };
  const handlePostChange = (value: any, option: any) => {
    setPostId(value);
    props.getPostReportList(props.token, value);
  };
  useEffect(() => {
    if (props.postReports && props.postReports.length) {
      setReports(
        props.postReports.map((r: any) => ({
          id: r.id,
          report_category: r.report_category,
          report_note: r.report_note,
          report_images: r.report_images,
          user: r.user,
          post: r.post,
          created_at: timeAgo(r.created_at),
          status: r.status,
        }))
      );
    }
  }, [props.postReports]);
  return (
    <Modal
      title="帖子举报记录查询"
      width={1200}
      open={props.isOpen}
      onCancel={props.hideModal}
      className="post-report-records-modal"
      footer={[
        <Button key="submit" type="primary" onClick={props.hideModal}>
          关闭
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="用户">
          <Space className="w-full">
            <Select
              autoClearSearchValue
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="输入用户昵称、ID 或 hashid"
              optionLabelProp="label"
              optionFilterProp="label"
              onChange={handleUserChange}
              onSearch={onUserSearch}
            >
              {users &&
                users.users &&
                users.users.map((user: any) => (
                  <Option
                    key={user.hashid}
                    value={user.hashid}
                    label={user.nickname + `（${user.hashid}）`}
                  >
                    <div key={user.hashid} className="flex align-items-center">
                      <img
                        src={user.avatar_url}
                        width={40}
                        height={40}
                        style={{ borderRadius: "5px" }}
                      />
                      <div className="mx-10">
                        <div>{user.nickname}</div>
                        <div style={{ color: "#5c6064" }}>ID:{user.hashid}</div>
                      </div>
                    </div>
                  </Option>
                ))}
              {options}
            </Select>
            <Select
              autoClearSearchValue
              showSearch
              value={postId}
              placeholder="请先输入用户后再选择相应的帖子"
              optionLabelProp="label"
              optionFilterProp="label"
              onChange={handlePostChange}
            >
              {posts &&
                posts.posts &&
                posts.posts.map((post: any) => (
                  <Option key={post.id} value={post.id} label={post.title}>
                    <div key={post.id} className="flex align-items-center">
                      <div className="mx-10">
                        <div>{post.title}</div>
                        <div style={{ color: "#5c6064" }}>ID:{post.id}</div>
                      </div>
                    </div>
                  </Option>
                ))}
              {options}
            </Select>
          </Space>
        </Form.Item>
      </Form>
      {reports && <Table columns={columns} dataSource={reports} />}
    </Modal>
  );
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
  postReports: state.post.postReports,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getPostReportList,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostReportRecordsModal);
