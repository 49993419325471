// views
import Link from "next/link";
import icon500 from "~/public/static/icon-500.png";
import logo from "~/public/static/logo.png";

const AppLogo = (props) => {
  // props
  const imageUrl = logo.src;
  const className = props.gray ? "img-gray" : "";

  return (
    <div>
      <Link href="/">
        <a aria-label="Go to head page">
          <img
            src={icon500.src}
            className={`${className} head`}
            width={32}
            height={32}
            alt="logo"
          />
          <img
            src={imageUrl}
            className={`${className} words`}
            width={63}
            height={20}
            alt="logo"
          />
          {props.withText && <span>只工作, 不上班</span>}
        </a>
      </Link>
      <style jsx>
        {`
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }

        a {
          display: flex;
          align-items:center;
        }

        .head{
          height:32px;
        }

        .words  {
          height: 20px;
          margin-left:7px;
          flex 1;
        }

        span {
          flex 1;
          height: 20px;
          line-height: 20px;
          font-size: 11px;
          color: #89837f;
          white-space: nowrap;
          border-left: 1px solid #d2cfce;
          margin-top: 3px;
          margin-left: 10px;
          padding-left: 10px;
        }
      `}
      </style>
    </div>
  );
};

export default AppLogo;
