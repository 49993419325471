import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Link from 'next/link'
import BaseModal from './BaseModal'
import AuthCodeFormItem from '~/components/forms/AuthCodeFormItem'
import Checkbox from '~/components/forms/Checkbox'
import { Form, Input, Button } from 'antd'
import { LockOutlined, MobileOutlined } from '@ant-design/icons'
import UserSignupNavs from '~/components/user/UserSignupNavs'

import {
  SIGNUP_BY_PHONE,
  signupByPhone
} from '../../redux/actions/user'
import {
  showAppModal
} from '~/redux/actions/app'
import { onInputChange } from '~/utils/form'

const Modal = props => {
  const [state, setState] = useState({
    phone: '',
    code: '',
    password: '',
    accept: true
  })

  const formDisabled = !state.accept || props.request[SIGNUP_BY_PHONE]

  const handleInputChange = onInputChange({ state, setState })
  const handleFormSubmit = () => {
    props.signupByPhone({
      phone: `+86${state.phone}`,
      code: state.code,
      password: state.password,
      inviteCode: props.inviteCode
    })
  }
  const handleGoSignin = event => {
    props.showAppModal('PhoneSigninModal')
    event.preventDefault()
  }

  // 暂时关闭邀请码
  // useEffect(() => {
  //   if (!props.inviteValid) {
  //     props.showAppModal('InviteVerifyModal')
  //   }
  // }, [])

  useEffect(() => {
    if (props.user) {
      props.hideModal()
      window.location.reload(true)
    }
  }, [props.user])

  return (
    <BaseModal
      isOpen={props.isOpen}
      hideModal={props.hideModal}
      showLogo
    >
      <UserSignupNavs selected='phone' />

      <Form onFinish={handleFormSubmit}>
        <Form.Item>
          <Input
            name='phone'
            placeholder='手机号码'
            prefix={<MobileOutlined />}
            value={state.phone}
            onChange={handleInputChange}
          />
        </Form.Item>

        <AuthCodeFormItem
          code={state.code}
          phone={state.phone}
          onChange={handleInputChange}
        />

        <Form.Item>
          <Input
            name='password'
            type='password'
            placeholder='密码'
            prefix={<LockOutlined />}
            value={state.password}
            onChange={handleInputChange}
          />
        </Form.Item>

        <div className='mb-20'>
          <Checkbox
            checked={state.accept}
            name='accept'
            onChange={handleInputChange}
          >
            <Link href='/terms'>
              <a className='text-primary'>我已阅读协议</a>
            </Link>
          </Checkbox>
        </div>

        <div className='mb-20'>
          <Button
            type='primary'
            htmlType='submit'
            disabled={formDisabled}
            block
          >
            立即注册
          </Button>
        </div>

        <div className='text-center'>
          <span className='mr-10'>已有账号</span>
          <a href='' className='text-primary' onClick={handleGoSignin}>去登录</a>
        </div>
      </Form>

      <style jsx global>{`
        .modal-logo {
          display: block;
          margin: 20px auto;
          height: 38px;
        }

        .navs {
          margin:  0;
          margin-bottom: 15px;
          padding: 0;
          list-style: none;
          display: table;
          table-layout: fixed;
          width: 100%;
          text-align: center;
          overflow: hidden;
          border-bottom: 2px solid #D8D8D8;
        }

        .navs li {
          display: table-cell;
        }

        .navs li a {
          display: block;
          text-decoration: none;
          color: #9B9B9B;
        }

        .navs li a span {
          display: block;
          padding: 18px 18px;
          line-height: 25px;
        }

        .navs li.active a {
          color: #000000;
        }

        .navs li.active a::after {
          display: block;
          line-height: 0;
          content: ' ';
          width: 40%;
          margin: 0 auto;
          border: 2px solid #F9BA48;
        }

        .form-group {
          padding: 10px;
          display: flex;
        }

        .form-group-policy {
          color: #F9BA48;
          font-size: 14px;
        }


        .form-control {
          flex: 1;
          font-size: 16px;
          line-height: 22px;
          padding: 11px 16px;
          border-radius: 2px;
          border: 1px solid rgba(216,216,216,1);
        }
      `}
      </style>
    </BaseModal>
  )
}

const mapStateToProps = (state) => ({
  request: state.app.request,
  user: state.user.user,
  inviteValid: state.user.inviteValid,
  inviteCode: state.user.inviteCode
})

const mapDispatchToProps = dispatch => bindActionCreators({
  signupByPhone,
  showAppModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
