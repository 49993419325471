import { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Form, Input, Button } from "antd";
import { MobileOutlined, LockOutlined } from "@ant-design/icons";
import BaseModal from "./BaseModal";
import UserSigninNavs from "~/components/user/UserSigninNavs";

import { signinByPhone, SIGNIN_BY_PHONE } from "~/redux/actions/user";
import { showAppModal } from "~/redux/actions/app";

const Modal = (props) => {
  const [state, setState] = useState({
    phone: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleFormSubmit = () => {
    props.signinByPhone({
      phone: `+86${state.phone}`,
      password: state.password,
    });
  };

  const handleGoReset = (event) => {
    props.showAppModal("PhoneResetModal");
    event.preventDefault();
  };

  const handleGoSignup = (event) => {
    props.showAppModal("PhoneSignupModal");
    event.preventDefault();
  };

  useEffect(() => {
    if (props.user) {
      props.hideModal();
      // window.location.reload(true);
      window.location.replace(window.location.href);
    }
  }, [props.user]);

  return (
    <BaseModal isOpen={props.isOpen} hideModal={props.hideModal} showLogo>
      <UserSigninNavs selected="phone" />

      <Form onFinish={handleFormSubmit}>
        <Form.Item>
          <Input
            name="phone"
            placeholder="手机号码"
            suffix=""
            prefix={<MobileOutlined className="site-form-item-icon" />}
            value={state.phone}
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item>
          <Input
            name="password"
            type="password"
            placeholder="密码"
            prefix={<LockOutlined />}
            value={state.password}
            onChange={handleInputChange}
          />
        </Form.Item>

        <div className="text-right mb-20">
          <a className="text-secondary" onClick={handleGoReset}>
            忘记密码？
          </a>
        </div>

        <div className="mb-20">
          <Button
            type="primary"
            htmlType="submit"
            disabled={props.request[SIGNIN_BY_PHONE]}
            block
          >
            立即登录
          </Button>
        </div>
        <p className="text-center my-10">
          <span className="mr-10">还没有账号？</span>
          <a href="" className="text-primary" onClick={handleGoSignup}>
            去注册
          </a>
        </p>
      </Form>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  codeRemains: state.user.codeRemains,
  codeText: state.user.codeText,
  user: state.user.user,
  request: state.app.request,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signinByPhone,
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
