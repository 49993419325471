import { request, authRequest } from "~/utils/api";
import { showAppMessage } from "~/redux/actions/app";
import settings from "~/settings";
/**
 * getGroups
 */
export const APPLY_TALENT_POST = "APPLY_TALENT_POST";
export const applyTalentPost = (token, postId, reason, answer, source) => {
  return (dispatch) => {
    authRequest(token, {
      method: "POST",
      url: "/talents/apply",
      data: { post_id: postId, reason, answer, source },
    })
      .then((res) => {
        dispatch({ type: APPLY_TALENT_POST, result: res });
        dispatch({
          type: CHECK_USER_RES,
          res: { detailed: true, applied: true },
        });
        dispatch(
          showAppMessage("申请成功，请留意站内消息已接收后续通知", "success")
        );
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const fetchRandomTalentPosts = (limit = 5, tags = "") => {
  let url = `/talents/random?limit=${limit}`;
  if (tags) url += `&tags=${encodeURIComponent(tags)}`;
  return request({ url }).then((res) => res.data);
};

export const CHECK_USER_RES = "CHECK_USER_RES";
export const checkUser = (token, postId) => {
  return (dispatch) => {
    authRequest(token, {
      method: "GET",
      url: `/talents/check?post_id=${postId}`,
    })
      .then((res) => {
        dispatch({ type: CHECK_USER_RES, res: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const TALENT_INFO = "TALENT_INFO";
export const talentInfo = (token) => {
  return (dispatch) => {
    authRequest(token, {
      method: "GET",
      url: "/talents/talent_info",
    })
      .then((res) => {
        dispatch({ type: TALENT_INFO, talentInfoResult: res.data.info });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const SET_APPLICATION_DATA = "SET_APPLICATION_DATA";
export const applications = ({ token }) => {
  return (dispatch) => {
    authRequest(token, {
      method: "GET",
      url: `/talents/applications`,
    })
      .then((res) => {
        dispatch({
          type: SET_APPLICATION_DATA,
          applicationData: res.data,
        });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};
export const SET_STATUS_HISTORY = "SET_STATUS_HISTORY";
export const statusHistory = ({ token, candidate_id }) => {
  return (dispatch) => {
    authRequest(token, {
      method: "GET",
      url: `/talents/status_history?candidate_id=${candidate_id}`,
    })
      .then((res) => {
        dispatch({
          type: SET_STATUS_HISTORY,
          statusHistoryData: res.data.status,
        });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};
export const CLEAR_STATUS_HISTORY = "CLEAR_STATUS_HISTORY";
export const clearStatusHistory = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATUS_HISTORY });
  };
};

export const reject = ({ token, candidate_id, reason = null, cb }) => {
  return (dispatch) => {
    authRequest(token, {
      method: "POST",
      url: "/talents/reject",
      data: { candidate_id, reason },
    })
      .then(() => {
        dispatch(showAppMessage("申请职位取消成功", "success"));
        cb();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const fetchOpenings = (postId) => {
  return request({
    baseURL: `${settings.talentApiUrl}/api/v1`,
    url: `/openings?eleduck_post_id=${postId}`,
  }).then((res) => res.data.openings);
};
