import { authRequest } from '~/utils/api'

import { showAppMessage } from '~/redux/actions/app'

export const GET_MESSAGE_COUNT = 'GET_MESSAGE_COUNT'
export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT'
export const getMessageCount = token => {
  return dispatch => {
    authRequest(token, {
      url: `/messages/read`,
      method: 'GET'
    }).then(res => {
      dispatch({ type: SET_MESSAGE_COUNT, count: res.data })
    }).catch(err => {
      dispatch(showAppMessage(err.message, 'error'))
    })
  }
}

export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST'
export const getMessageList = (token, params) => {
  return dispatch => {
    authRequest(token, {
      url: `/messages`,
      method: 'GET',
      params: params
    }).then(res => {
      dispatch({ type: SET_MESSAGE_LIST, list: res.data })
      dispatch(getMessageCount(token))
    }).catch(err => {
      dispatch(showAppMessage(err.message, 'error'))
    })
  }
}