import { request, authRequest } from "~/utils/api";
import {
  openRequest,
  closeRequest,
  showAppMessage,
  fireEvent,
} from "~/redux/actions/app";

export const CREATE_COMMENT = "CREATE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const RESET_COMMENTS = "RESET_COMMENTS";
export const DELETE_COMMENT = "DELETE_COMMENT";

export const createComment = (token, params, callback) => {
  return (dispatch) => {
    dispatch(openRequest(CREATE_COMMENT));
    authRequest(token, {
      url: `/posts/${params.postId}/comments`,
      method: "POST",
      data: {
        parent_id: params.parentId,
        content: params.content,
        raw_content: params.raw_content,
      },
    })
      .then((res) => {
        dispatch(closeRequest(CREATE_COMMENT));
        dispatch({ type: CREATE_COMMENT, comment: res.data.comment });
        callback(res.data.comment);
      })
      .catch((err) => {
        dispatch(closeRequest(CREATE_COMMENT));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const updateComment = (token, params, callback) => {
  return (dispatch) => {
    dispatch(openRequest(UPDATE_COMMENT));
    authRequest(token, {
      url: `/posts/${params.postId}/comments/${params.id}`,
      method: "PATCH",
      data: {
        content: params.content,
        raw_content: params.raw_content,
      },
    })
      .then((res) => {
        dispatch(closeRequest(UPDATE_COMMENT));
        dispatch({ type: UPDATE_COMMENT, comment: res.data.comment });
        callback(res.data.comment);
      })
      .catch((err) => {
        dispatch(closeRequest(UPDATE_COMMENT));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const deleteComment = (token, comment, data = {}) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/posts/${comment.post_id}/comments/${comment.id}`,
      method: "DELETE",
      data: data,
    })
      .then((res) => {
        dispatch({ type: DELETE_COMMENT, comment: comment, deletedComment: res.data.comment });
        dispatch(showAppMessage("评论删除成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const resetComments = () => {
  return {
    type: RESET_COMMENTS,
  };
};

export const GET_POST_COMMENTS = "GET_POST_COMMENTS";
export const SET_POST_COMMENTS = "SET_POST_COMMENTS";
export const getPostComments = (postId, params) => {
  return (dispatch) => {
    dispatch(openRequest(GET_POST_COMMENTS));
    request({
      url: `/posts/${postId}/comments`,
      method: "GET",
      params: {
        page: params.page,
        sort: params.sort,
      },
    })
      .then((res) => {
        dispatch(closeRequest(GET_POST_COMMENTS));
        //reset commentList is empty when page 1
        if (params.page == 1) {
          dispatch({ type: RESET_COMMENTS });
        }
        dispatch({ type: SET_POST_COMMENTS, list: res.data });
        const ids = [];
        _.each(res.data.comments, (comment) => {
          ids.push(comment.id);
          ids.push(..._.map(comment.replies, "id"));
        });
        dispatch(fireEvent(GET_POST_COMMENTS, ids));
      })
      .catch((err) => {
        dispatch(closeRequest(GET_POST_COMMENTS));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * 增加评论ssr接口
 * @param postId
 * @param params
 */
export const fetchPostComments = (postId, params) => {
  return request({
    url: `/posts/${postId}/comments`,
    method: "GET",
    params: {
      page: params.page,
      sort: params.sort,
    },
  }).then((res) => res.data);
};

/**
 * Check flags
 */
export const CHECK_COMMENT_FLAGS = "CHECK_COMMENT_FLAGS";
export const checkCommentFlags = (token, flags, ids) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/comments/check",
      method: "POST",
      data: { flags, ids },
    })
      .then((res) => {
        dispatch({ type: CHECK_COMMENT_FLAGS, flags: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/**
 * Votes
 */
export const VOTE_COMMENT = "VOTE_COMMENT";
export const UNVOTE_COMMENT = "UNVOTE_COMMENT";
export const voteComment = (token, id) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/comments/${id}/vote`,
      method: "POST",
    })
      .then((res) => {
        dispatch({ type: VOTE_COMMENT, id });
        dispatch(fireEvent(VOTE_COMMENT, id));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};
export const unvoteComment = (token, id) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/comments/${id}/vote`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch({ type: UNVOTE_COMMENT, id });
        dispatch(fireEvent(UNVOTE_COMMENT, id));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};
