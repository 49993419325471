import { useRouter } from "next/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import BaseModal from "./BaseModal";
import { WarningFilled } from "@ant-design/icons";
import { Alert, Collapse } from "antd";

import { showAppModal, showAppMessage, setReport } from "~/redux/actions/app";

import { postReport } from "~/redux/actions/user";
import { isMobile } from "react-device-detect";
import { hasAnyRole } from "../../utils/user";

const { Panel } = Collapse;

const NewJobModal = (props) => {
  const router = useRouter();
  const isHrOrAdmin = hasAnyRole(props.user, ["admin", "hr"]);
  const Header = ({ className }) => (
    <div className={`text-center header ${className}`}>
      {isMobile && <div className="font-32  modal-title">发布招聘</div>}
      {isMobile ? (
        <div className="tip">
          请根据您所要招聘的职位类型以及预算选择对应的招聘方式：）
        </div>
      ) : (
        <Alert
          message="请根据您所要招聘的职位类型以及预算选择对应的招聘方式：）"
          type="warning"
          showIcon
          icon={<WarningFilled />}
        />
      )}
    </div>
  );
  const list1 = (
    <div className="py-20">
      <div className="list">
        <div className="list-i">
          <img src="/static/icons/calendar@3x.png" />
          <div>
            <div className="text1">适用于外包零活/短期兼职等非全职职位</div>
            <div className="text2">
              社区发帖需遵守
              <span
                className="link"
                onClick={() =>
                  window.open("https://eleduck.com/posts/6GzfGe", "_blank")
                }
              >
                《社区规则》
              </span>
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/green_ele@3x.png" />
          <div>
            <div className="text1">发帖招聘会消耗电量</div>
            <div className="text2">
              请认真对待每次招聘写清楚项目介绍、薪资酬劳、结算方式等必要信息
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/contact@3x.png" />
          <div>
            <div className="text1">将联系方式填在对应的位置</div>
            <div className="text2">而不是公开在正文和评论区</div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/friend@3x.png" />
          <div className="text1">
            请及时回复用户的申请或添加，并给出友好的结果反馈
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/post@3x.png" />
          <div className="text1">
            若招满或暂停，请在标题里更新招聘状态或隐藏帖子
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/warning@3x.png" />
          <div>
            <div className="text1">
              违法违规内容请勿发布，我们会留存证据并移交给网警
            </div>
          </div>
        </div>
      </div>
      <div className="btn" onClick={() => router.push("/jobs/new?c=jd")}>
        开始
      </div>
    </div>
  );

  const list2 = (
    <div className="py-20">
      <div className="list">
        <div className="list-i">
          <img src="/static/icons/group@3x.png" />
          <div>
            <div className="text1">社区远程人才</div>
            <div className="text2">
              开发者26400+, 设计师 6200+, 项目/产品3100+，海外华人 2300+
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/clock@3x.png" />
          <div>
            <div className="text1">节省大量时间</div>
            <div className="text2">
              社区安排专属HR，帮助完善JD、简历筛选、安排面试等服务，省时省力
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/precise@3x.png" />
          <div>
            <div className="text1">简历更为精准</div>
            <div className="text2">
              HR会根据你方要求，精准筛选/推荐社区的远程人才，简历匹配度达70%
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/alarm_clock@3x.png" />
          <div>
            <div className="text1">招聘用时更短</div>
            <div className="text2">
              相比社区发帖被动等待，直接使用远程人才推荐服务，3-7天即可完成招聘
            </div>
          </div>
        </div>
        <div className="list-i">
          <img src="/static/icons/wallet@3x.png" />
          <div>
            <div className="text1">入职后再付费 </div>
            <div className="text2">
              人才入职成功后，只收取公司该职位月薪30-50%作为服务费用，业界良心
            </div>
          </div>
        </div>
      </div>
      <div
        className="btn"
        onClick={() => {
          if (isHrOrAdmin) {
            router.push("/jobs/new?c=talent");
          } else {
            window.open("https://eleduck.com/posts/z1f0Kp");
          }
        }}
      >
        开始
      </div>
    </div>
  );

  const list3 = (
    <div className="py-20">
      <div className="list list3">
        <div className="list-i ">
          <img src="/static/icons/bullseye@3x.png" />
          <div>
            <div className="text3">平价猎头服务</div>
          </div>
        </div>
        <div className="bullet-points">
          <div>电鸭社区的猎头服务费低至10-18%</div>
          <div>依托社区人才库，3-5天提供候选人</div>
        </div>
        <div className="list-i list-i-mt">
          <img src="/static/icons/bag@3x.png" />
          <div className="text3">自由HR服务</div>
        </div>
        <div className="bullet-points">
          <div>如果仅需雇佣一位兼职或短期的HR</div>
          <div>可以在电鸭挑选一位自由HR来合作</div>
        </div>
      </div>
      <div
        className="btn"
        onClick={() => window.open("https://eleduck.com/posts/kRfVra")}
      >
        开始
      </div>
    </div>
  );
  return (
    <BaseModal
      className="newjob-modal"
      noClose
      isOpen={props.isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      hideModal={props.hideModal}
      showLogo={false}
      header={isMobile && <Header class="show-on-mobile" />}
    >
      <div className="newjob-body">
        <div className="item item1">
          <Collapse className="show-on-mobile" bordered={false}>
            <Panel
              showArrow={false}
              header={
                <>
                  <div className="title-w">
                    <div className="title">社区发帖招聘</div>
                    <div className="arrow"></div>
                  </div>
                  <div className="sub-title">
                    <WarningFilled />
                    适用于外包零活、短期项目的招聘
                  </div>
                </>
              }
            >
              {list1}
            </Panel>
          </Collapse>
          <div className="show-on-pc">
            <div className="header">
              <div className="title">社区发帖招聘</div>
              <div className="sub-title">
                <WarningFilled />
                适用于外包零活、短期项目的招聘
              </div>
            </div>
            {list1}
          </div>
        </div>
        <div className="item item2">
          <Collapse className="show-on-mobile" bordered={false}>
            <Panel
              showArrow={false}
              header={
                <>
                  <div className="title-w">
                    <div className="title">远程人才推荐</div>
                    <div className="arrow"></div>
                  </div>
                  <div className="sub-title">
                    <WarningFilled />
                    适用于远程人才的快速&精准招聘
                  </div>
                </>
              }
            >
              {list2}
            </Panel>
          </Collapse>
          <div className="show-on-pc">
            <div className="header">
              <div className="title">远程人才推荐</div>
              <div className="sub-title">
                <WarningFilled />
                适用于远程人才的快速&精准招聘
              </div>
            </div>
            {list2}
          </div>
        </div>
        <div className="item item3">
          <Collapse className="show-on-mobile" bordered={false}>
            <Panel
              showArrow={false}
              header={
                <>
                  <div className="title-w">
                    <div className="title">企业招聘服务</div>
                    <div className="arrow"></div>
                  </div>
                  <div className="sub-title">
                    <WarningFilled />
                    适用于初创团队、高级人才的招聘
                  </div>
                  <div className="line"></div>
                  <div className="services">
                    <div>自由HR服务</div>
                    <div>平价猎头服务</div>
                  </div>
                </>
              }
            >
              {list3}
            </Panel>
          </Collapse>
          <div className="show-on-pc">
            <div className="header">
              <div className="title">企业招聘服务</div>
              <div className="sub-title">
                <WarningFilled />
                适用于初创团队、高级人才的招聘
              </div>
            </div>
            {list3}
          </div>
        </div>
      </div>
      {/* <Alert
        message="请根据您所要招聘的职位类型以及预算选择对应的招聘方式：）"
        type="warning"
        showIcon
        icon={<WarningFilled />}
      /> */}
      {!isMobile && <Header />}
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
  report: state.app.report,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      showAppMessage,
      setReport,
      postReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewJobModal);
