// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { signout } from "~/redux/actions/user";
import { showAppModal } from "~/redux/actions/app";

// views
import { Row, Col } from "antd";
import AppLogo from "~/components/app/AppLogo";
import AppMenuDrawer from "~/components/app/AppMenuDrawer";
import AppMenuLeft from "~/components/app/AppMenuLeft";
import AppMenuRight from "~/components/app/AppMenuRight";
import NewPostCard from "~/components/sider/NewPostCard";
import AppAvatarDropdownMenu from "./AppAvatarDropdownMenu";

const AppHeader = (props) => {
  return (
    <div className="app-header">
      <div className="container">
        <div className="inner">
          <div className="app-brand">
            <AppLogo withText title={props.title} isPost={props.isPost} />
          </div>
          <div className="app-menu-desktop">
            <Row type="flex" justify="space-between" style={{ height: "100%" }}>
              <Col>
                <AppMenuLeft mode="horizontal" />
              </Col>
              <Col>
                <AppMenuRight mode="horizontal" />
              </Col>
            </Row>
          </div>
          <div className="app-menu-mobile">
            <AppAvatarDropdownMenu />
            {/* <AppMenuDrawer>
              <NewPostCard />
              <AppMenuLeft mode='inline' />
              <AppMenuRight mode='inline' />
            </AppMenuDrawer> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signout,
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
