import {
  REFRESH_AUTH_CODE_TEXT,
  SET_USER_TOKEN,
  SET_USER_INFO,
  SET_INVITE_CODE,
  SET_INVITE_VALID,
  SET_WECHAT_TOKEN,
  EXP_WECHAT_TOKEN,
  SET_USER_SUGGESTS,
  SET_USER_RANKS,
  SET_USER_CHECKIN,
  SET_EXP_RECORD_LIST,
  SET_ELE_RECORD_LIST,
  SET_USER_COMMENT_LIST,
  SET_WECHAT_CODE,
} from "../actions/user";

const initialState = {
  codeRemains: 0,
  token: null,
  user: null,
  userDetailed: null,
  inviteCode: null,
  inviteValid: false,
  wechatToken: null,
  wechatTokenExpired: true,
  freshUsers: [],
  activeUsers: [],
  ranks: null,
  checkin: null,
  monthlyCheckins: 0,
  expRecordList: null,
  eleRecordList: null,
  wechatCode: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_AUTH_CODE_TEXT:
      return {
        ...state,
        codeRemains: action.codeRemains,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_USER_INFO:
      return {
        ...state,
        user: action.user,
      };
    case SET_INVITE_CODE:
      return {
        ...state,
        inviteCode: action.inviteCode,
      };
    case SET_INVITE_VALID:
      return {
        ...state,
        inviteValid: action.inviteValid,
      };
    case SET_WECHAT_TOKEN:
      return {
        ...state,
        wechatToken: action.token,
        wechatTokenExpired: false,
      };
    case EXP_WECHAT_TOKEN:
      return {
        ...state,
        wechatTokenExpired: true,
      };
    case SET_USER_SUGGESTS:
      return {
        ...state,
        freshUsers: action.freshUsers,
        activeUsers: action.activeUsers,
      };
    case SET_USER_RANKS:
      return {
        ...state,
        ranks: action.users,
      };
    case SET_USER_CHECKIN:
      return {
        ...state,
        checkin: action.checkin,
        monthlyCheckins: action.monthlyCheckins,
      };
    case SET_EXP_RECORD_LIST:
      return {
        ...state,
        expRecordList: action.list,
      };
    case SET_ELE_RECORD_LIST:
      return {
        ...state,
        eleRecordList: action.list,
      };
    case SET_USER_COMMENT_LIST:
      return {
        ...state,
        userCommentList: action.list,
      };
    case SET_WECHAT_CODE:
      return {
        ...state,
        wechatCode: action.code,
      };
    default:
      return state;
  }
};
