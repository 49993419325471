import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Menu } from 'antd'

import { showAppModal } from '~/redux/actions/app'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => bindActionCreators({
  showAppModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const handleClick = event => {
    if (props.selected !== event.key) {
      if (event.key === 'phone') {
        props.showAppModal('PhoneSignupModal')
      } else if (event.key === 'wechat') {
        props.showAppModal('WechatSignupModal')
      }
    }
  }

  const items = [
    {
      label: "手机注册",
      key: "phone"
    },
    {
      label: "微信注册",
      key: "wechat"
    }
  ]

  return (
    <Menu
      mode='horizontal'
      selectedKeys={[props.selected]}
      onClick={handleClick}
      items={items}
    />
  )
})
